import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'md-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent implements OnInit, OnDestroy {
  @Output() countdownStarted = new EventEmitter<void>();
  @Output() countdownEnded = new EventEmitter<void>();
  days = 0;
  hours = 0;
  minutes = 0;
  seconds = 0;
  imagePath: string;
  showCountdown = false;
  private countdownSubscription!: Subscription;
  currentTargetDate: number;

  private readonly countdownStartDate = new Date('2024-11-18T19:15:00+04:00').getTime();
  private readonly firstTargetDate = new Date('2024-11-26T23:59:59+04:00').getTime();
  private readonly finalTargetDate = new Date('2024-12-01T23:59:00+04:00').getTime();

  constructor(private router: Router) {
    this.currentTargetDate = this.firstTargetDate;
  }

  ngOnInit(): void {
    // Subscribe to route changes
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.checkUrlAndSetVisibility();
      }
    });

    // Initial check for visibility
    this.checkUrlAndSetVisibility();

    this.countdownSubscription = interval(1000).subscribe(() => {
      const now = new Date().getTime();

      if (now >= this.countdownStartDate && now < this.finalTargetDate) {
        if (!this.showCountdown) {
          this.showCountdown = true;
          this.countdownStarted.emit();
        }
        this.updateImagePath();
        this.updateTime(this.currentTargetDate);
      } else {
        this.showCountdown = false;
        this.imagePath = '';
        this.countdownEnded.emit();
        this.resetCountdown();
        this.unsubscribeCountdown();
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeCountdown();
  }

  private checkUrlAndSetVisibility(): void {
    const currentUrl = this.router.url;
    const hiddenRoutes = [
      '/deal',
      '/rewardpoints',
      '/customer',
      '/customeraccount',
      '/review',
      '/checkout',
      '/checkoutpaymentSuccess',
      '/invoicenew?',
    ];
    this.showCountdown = !hiddenRoutes.some(route => currentUrl.startsWith(route));
  }

  private updateImagePath(): void {
    const isMobile = window.innerWidth <= 768;
    const now = new Date().getTime();

    if (now >= this.countdownStartDate && now < this.firstTargetDate) {
      this.imagePath = isMobile
        ? 'assets/img/bf-banners/midnightmidnightMob.jpg'
        : 'assets/img/bf-banners/BlackFridayStartsInDesktop.jpg';
    } else if (now >= this.firstTargetDate && now < this.finalTargetDate) {
      this.imagePath = isMobile
        ? 'assets/img/bf-banners/BlackFridayEndsInMob.jpg'
        : 'assets/img/bf-banners/BlackFridayEndsInDesktop.jpg';
    } else {
      this.imagePath = '';
    }
  }

  private updateTime(targetDate: number): void {
    const now = new Date().getTime();
    const timeLeft = targetDate - now;

    if (timeLeft > 0) {
      this.days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
    } else {
      if (this.currentTargetDate === this.firstTargetDate) {
        this.currentTargetDate = this.finalTargetDate;
      } else {
        this.resetCountdown();
      }
    }
  }

  private resetCountdown(): void {
    this.days = 0;
    this.hours = 0;
    this.minutes = 0;
    this.seconds = 0;
  }

  private unsubscribeCountdown(): void {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
  }
}
