import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { Router, Event, NavigationEnd, NavigationStart } from '@angular/router';
import { OverlayService } from 'src/app/shared/overlay.service';

@Component({
  selector: 'md-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent implements OnInit {

  showCloseButton = false;

  isVisible = false;
  private readonly targetUrlFragment = 'plp/Black%20Friday/34'; // Set the specific URL fragment here

  private readonly startTime = new Date('2024-11-18T19:15:00+04:00');
  private readonly endTime = new Date('2024-11-28T23:59:00+04:00'); // Set your desired end time here

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private elRef: ElementRef,
    private overlayService: OverlayService // Inject the OverlayService
  ) {}

  ngOnInit(): void {
    // Check if the current URL matches the target URL fragment
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd || event instanceof NavigationStart) {
        this.checkOverlayVisibility();
      }
    });

    // Continuously check time to ensure overlay updates without page reload
    setInterval(() => {
      this.checkOverlayVisibility();
    }, 1000 * 60); // Check every minute
  }

  private checkOverlayVisibility(): void {
    const currentUrl = this.router.url;
    const currentTime = new Date();

    if (currentUrl.includes(this.targetUrlFragment) && currentTime >= this.startTime && currentTime <= this.endTime) {
      this.showOverlay();
      this.showCloseButton = true; 
    } else {
      this.hideOverlay();
      this.showCloseButton = false;

    }
  }

  private showOverlay(): void {
    if (!this.isVisible) {
      this.isVisible = true;
      this.overlayService.setOverlayVisible(true); 
      this.renderer.addClass(document.body, 'overlay-active'); 
      this.renderer.setStyle(document.body, 'overflow', 'hidden'); 


      // Apply blur to app-category-list div
      const appCategoryList = this.elRef.nativeElement.querySelector('.app-category-list');
      if (appCategoryList) {

        this.renderer.addClass(appCategoryList, 'blur-content'); 
        this.renderer.setStyle(appCategoryList, 'pointer-events', 'none'); 

      }

      // Apply blur to app-filter-data div
      const appFilterData = document.querySelector('.app-filter-data');
      if (appFilterData) {
        this.renderer.addClass(appFilterData, 'blur-content'); 
        this.renderer.setStyle(appFilterData, 'pointer-events', 'none');

      }
    }
  }

  private hideOverlay(): void {
    if (this.isVisible) {
      this.isVisible = false;

      this.overlayService.setOverlayVisible(false); 
      this.renderer.removeClass(document.body, 'overlay-active'); 
      this.renderer.removeStyle(document.body, 'overflow'); 
    
      const appCategoryList = this.elRef.nativeElement.querySelector('.app-category-list');
      if (appCategoryList) {
        this.renderer.removeClass(appCategoryList, 'blur-content'); 
        this.renderer.setStyle(appCategoryList, 'pointer-events', 'auto'); 

      }


      const appFilterData = document.querySelector('.app-filter-data');
      if (appFilterData) {

        this.renderer.removeClass(appFilterData, 'blur-content'); 
        this.renderer.setStyle(appFilterData, 'pointer-events', 'auto'); 

      }
    }
  }
}
