<div class="container-fluid">
    <div  id="home-banner-container-1" class="home-banner-container">
        <img
            [src]="bannerUrl"
            alt="Banner Image"
            class="h-t-banner"
            (click)="redirectToLink1()">
    </div> 

    <div class="h-category-carousel h-block">
        <div
            *ngIf="
                !trendingEntries ||
                (trendingEntries && trendingEntries.length > 0)
            "
            class="h-category-title"
        >
            <h2>{{ "Trending" | translate }}</h2>
        </div>
        <div *ngIf="trendingEntries">
            <div mdDesktopOnly>
                <div class="h-trending-desktop-container h-presenter">
                    <div
                        *ngFor="let dealEntry of trendingEntries"
                        class="h-trending-desktop-item"
                    >
                        <md-product-card
                            [dealEntry]="dealEntry"
                            (dealSelected)="dealSelected($event)"
                        ></md-product-card>
                    </div>
                </div>
            </div>
            <div mdMobileOnly class="h-category-carousel" id="swiper-trending">
                <swiper [config]="swiperConfigTrending" class="custom-swiper">
                    <div class="swiper-wrapper h-swiper-container">
                        <div
                            class="swiper-slide"
                            *ngFor="let dealEntry of trendingEntries"
                        >
                            <md-product-card
                                [dealEntry]="dealEntry"
                                (dealSelected)="dealSelected($event)"
                            ></md-product-card>
                        </div>
                    </div>
                </swiper>
                <div
                    mdDesktopOnly
                    class="swiper-button-next swiper-trending-button-next"
                ></div>
                <div
                    mdDesktopOnly
                    class="swiper-button-prev swiper-trending-button-prev"
                ></div>
            </div>
        </div>
        <div *ngIf="!trendingEntries">
            <div mdDesktopOnly>
                <div class="h-trending-desktop-container h-presenter">
                    <div
                        *ngFor="
                            let dealEntry of trendingEntriesLoadingPlaceholder
                        "
                        class="h-trending-desktop-item"
                    >
                        <md-product-card-loading />
                    </div>
                </div>
            </div>

            <div mdMobileOnly class="h-category-carousel">
                <swiper [config]="swiperConfigTrending" class="custom-swiper">
                    <div class="swiper-wrapper h-swiper-container">
                        <div
                            class="swiper-slide"
                            *ngFor="let dealEntry of [0, 1, 2]"
                        >
                            <md-product-card-loading />
                        </div>
                    </div>
                </swiper>
                <div
                    mdMobileOnly
                    class="swiper-button-next swiper-trending-button-next"
                ></div>
                <div
                    mdMobileOnly
                    class="swiper-button-prev swiper-trending-button-prev"
                ></div>
            </div>
        </div>
    </div>
    <div class="h-category-carousel h-block">
        <div
            class="h-category-title"
            *ngIf="
                showRecentlyViewed &&
                ((recentlyViewedEntries && recentlyViewedEntries.length > 0) ||
                    !recentlyViewedEntries)
            "
        >
            <h2>{{ "Recently Viewed Deals" | translate }}</h2>
        </div>
        <div
            *ngIf="
                showRecentlyViewed &&
                ((recentlyViewedEntries && recentlyViewedEntries.length > 0) ||
                    !recentlyViewedEntries)
            "
            class="h-category-carousel"
            id="swiper-recently"
        >
            <swiper
                *ngIf="
                    recentlyViewedEntries && recentlyViewedEntries.length > 0
                "
                [config]="swiperConfigRecentlyViewed"
                class="custom-swiper"
            >
                <div class="swiper-wrapper h-swiper-container">
                    <div
                        class="swiper-slide"
                        *ngFor="let dealEntry of recentlyViewedEntries"
                    >
                        <md-product-card
                            [dealEntry]="dealEntry"
                            (dealSelected)="dealSelected($event)"
                        ></md-product-card>
                    </div>
                </div>
            </swiper>
            <div
                mdDesktopOnly
                class="swiper-recently-viewed-button-next swiper-button-next"
            ></div>
            <div
                mdDesktopOnly
                class="swiper-recently-viewed-button-prev swiper-button-prev"
            ></div>
        </div>
        <div *ngIf="!recentlyViewedEntries">
            <swiper
                *ngIf="!recentlyViewedEntries"
                [config]="swiperConfigRecentlyViewed"
                class="custom-swiper"
            >
                <div class="swiper-wrapper h-swiper-container">
                    <div
                        class="swiper-slide"
                        *ngFor="let dealEntry of getRepeatArray(5)"
                    >
                        <md-product-card-loading />
                    </div>
                </div>
                <div
                    mdDesktopOnly
                    class="swiper-button-next swiper-recently-viewed-button-next"
                ></div>
                <div
                    mdDesktopOnly
                    class="swiper-button-prev swiper-recently-viewed-button-prev"
                ></div>
            </swiper>
        </div>
    </div>
    <div *ngIf="bestSellerCategories">
        <div
            class="h-category-carousel"
            *ngFor="
                let bestSellerCategory of bestSellerCategories.categories;
                let i = index
            "
        >
            <ng-container *ngIf="bestSellerCategory.data.length > 0">
                <div class="h-category-title">
                    <h2>
                        {{ bestSellerCategory.category | translate }}
                    </h2>
                </div>
                <div
                    class="h-category-carousel"
                    [attr.id]="'swiper-best-sellers-' + i"
                >
                    <swiper [config]="getSwiperConfig(i)" class="custom-swiper">
                        <div class="swiper-wrapper h-swiper-container h-block">
                            <div
                                class="swiper-slide"
                                *ngFor="
                                    let dealEntry of bestSellerCategory.data
                                "
                            >
                                <md-product-card
                                    [dealEntry]="dealEntry"
                                    (dealSelected)="dealSelected($event)"
                                ></md-product-card>
                            </div>
                        </div>
                    </swiper>
                    <div
                        mdDesktopOnly
                        class="swiper-button-next swiper-best-sellers-button-next-{{
                            i
                        }}"
                    ></div>
                    <div
                        mdDesktopOnly
                        class="swiper-button-prev swiper-best-sellers-button-prev-{{
                            i
                        }}"
                    ></div>
                </div>
            </ng-container>
            <div
                *ngIf="i === 2 && storeDetails?.bannerData"
                class="home-banner-container"
            >
                <img
                    [src]="bannerUrl1"
                    alt="Banner Image"
                    class="h-t-banner"
                    (click)="redirectToLink()"
                />
            </div>
        </div>
    </div>
    <div
        *ngIf="!bestSellerCategories"
        class="h-category-carousel"
        style="margin-bottom: 100px"
    >
        <div class="h-category-title">
            <h2>
                {{ "Hotels" | translate }}
            </h2>
        </div>
        <swiper [config]="swiperConfig" class="custom-swiper">
            <div class="swiper-wrapper h-swiper-container">
                <div
                    class="swiper-slide"
                    *ngFor="let dealEntry of getRepeatArray(5)"
                >
                    <md-product-card-loading />
                </div>
            </div>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
        </swiper>
    </div>
</div>
<div
    class="h-upcoming-container"
    *ngIf="showUpcomingTrip"
    (click)="goToTripDetail()"
>
    <img class="h-upcoming-image" [src]="upcomingTrip?.image" />
    <div class="h-upcoming-details">
        <div class="h-upcoming-collapse">
            {{ "Your Upcoming Trip" | translate }}
            <img
                (click)="hideUpcomingTrip()"
                src="assets/img/Vector.png"
                class="vector-img"
            />
        </div>
        <div class="h-upcoming-outlet-name">
            {{ upcomingTrip?.outlet_name }}
        </div>
        <div class="h-upcoming-location">
            <img
                [src]="
                    'assets/img/icons-d/General Icons_Location - AI Blue3 24x24px.svg'
                "
                style="width: 23px; margin-left: -4px"
            />
            {{ upcomingTrip?.location_name }}
        </div>
        <div class="h-upcoming-dates">
            <img
                [src]="
                    'assets/img/ICONS/General Icons_Check In Calendar - AI Blue3 24x24px.svg'
                "
                style="width: 20px; height: 17px"
            />
            &nbsp;{{ upcomingTrip?.checkin | date : "MMM dd" }},
            {{ upcomingTrip?.checkin | date : "yyyy" }} &nbsp;&nbsp;
            <img
                [src]="
                    'assets/img/ICONS/General Icons_Check Out Calendar - AI Blue3 24x24px.svg'
                "
                style="width: 20px; height: 17px"
            />
            &nbsp;{{ upcomingTrip?.checkout | date : "MMM dd" }},
            {{ upcomingTrip?.checkout | date : "yyyy" }}
        </div>
    </div>
</div>
