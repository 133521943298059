import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core';
import {
    ProductDetails,
    ProductServiceOption,
} from 'src/app/product-detail/model/product-detail.model';
import {
    BookingConfiguredData,
    MinimalProductBookingData,
    getProductServiceOptionsFromProductDetails,
} from '../booking-config.model';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import { EventService, EventType } from 'src/app/utilities/event.service';
import { CounterComponent } from 'src/app/controls/counter/counter.component';

@Component({
    selector: 'md-activity-booking',
    templateUrl: './activity-booking.component.html',
    styleUrls: ['./activity-booking.component.scss'],
})
export class ActivityBookingComponent implements OnInit {
    @Input() productDetails: ProductDetails;
    @Input() initialBookingData: MinimalProductBookingData;

    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';
    selectedServiceOptionQuantities: Map<ProductServiceOption, number> =
        new Map();

    @Output() bookingConfigured = new EventEmitter<BookingConfiguredData>();

    @ViewChildren(CounterComponent) counters!: QueryList<CounterComponent>;

    constructor(
        private currencyLanguageService: CurrencyLanguageService,
        private eventService: EventService
    ) {}

    ngOnInit(): void {
        if (this.initialBookingData) {
            const minimalProductServiceOptions = Array.from(
                this.initialBookingData.selectedServiceOptionQuantities.keys()
            );
            const productServiceOptions =
                getProductServiceOptionsFromProductDetails(
                    this.productDetails,
                    minimalProductServiceOptions
                );
            minimalProductServiceOptions.forEach((option) => {
                const quantity =
                    this.initialBookingData.selectedServiceOptionQuantities.get(
                        option
                    );
                const productServiceOption = productServiceOptions.find(
                    (serviceOption) =>
                        serviceOption.id === option.id &&
                        serviceOption.occupancyId === option.occupancyId
                );
                this.selectedServiceOptionQuantities.set(
                    productServiceOption,
                    quantity
                );
            });
        }
        this.currencyLanguageService.getCurrency().subscribe((currency) => {
            this.currencySymbol = currency;
        });
        this.currencyLanguageService
            .getLocaleForCurrency()
            .subscribe((locale) => {
                this.locale = locale;
            });
    }

    onQuantityChanged(
        option: ProductServiceOption,
        quantity: number,
        categoryIndex: number,
        optionIndex: number
    ) {
        const isSameCategoryId = this.checkSameCategoryIdAndNotifyCustomer(
            option,
            this.selectedServiceOptionQuantities
        );
        if (!isSameCategoryId) {
            const index = this.getListIndexBasedOnCategoryAndOptionIndex(
                categoryIndex,
                optionIndex
            );
            this.counters.toArray()[index].resetCounter();
            return;
        }
        this.setSelectedServiceOptionQuantitiesOrRemoveOption(option, quantity);
        this.emitBookingConfigured();
    }

    private setSelectedServiceOptionQuantitiesOrRemoveOption(
        option: ProductServiceOption,
        quantity: number
    ) {
        if (quantity === 0) {
            this.selectedServiceOptionQuantities.delete(option);
        } else {
            this.selectedServiceOptionQuantities.set(option, quantity);
        }
    }

    private getListIndexBasedOnCategoryAndOptionIndex(
        categoryIndex: number,
        optionIndex: number
    ): number {
        let index = 0;
        for (let i = 0; i < categoryIndex; i++) {
            index +=
                this.productDetails.serviceOptionCategories[i].options.length;
        }
        index += optionIndex;
        return index;
    }

    private checkSameCategoryIdAndNotifyCustomer(
        option: ProductServiceOption,
        selectedServiceOptionQuantities: Map<ProductServiceOption, number>
    ): boolean {
        const selectedServiceOption = Array.from(
            selectedServiceOptionQuantities.keys()
        ).find(
            (serviceOption) => serviceOption.categoryId !== option.categoryId
        );
        if (selectedServiceOption) {
            this.eventService.emitEvent(
                EventType.MODAL_INFO,
                'Multiple options cannot be added to the cart simultaneously. Please add each option separately.'
            );
            return false;
        }
        return true;
    }

    private emitBookingConfigured() {
        const totalPrice = this.calculateTotalPrice();
        const totalFullPrice = this.calculateTotalFullPrice();
        this.bookingConfigured.emit({
            totalPrice,
            totalFullPrice,
            productBookingData: {
                id: this.productDetails.id,
                type: this.productDetails.type,
                selectedServiceOptionQuantities:
                    this.selectedServiceOptionQuantities,
            },
        });
    }

    private calculateTotalPrice() {
        let totalPrice = 0;
        this.selectedServiceOptionQuantities.forEach((quantity, option) => {
            totalPrice += option.price * quantity;
        });
        return totalPrice;
    }

    private calculateTotalFullPrice() {
        let totalFullPrice = 0;
        this.selectedServiceOptionQuantities.forEach((quantity, option) => {
            totalFullPrice += option.fullPrice * quantity;
        });
        return totalFullPrice;
    }
}
