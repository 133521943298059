import { Component, OnInit, OnDestroy, HostListener, Output, EventEmitter, Renderer2 } from '@angular/core';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'md-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent implements OnInit, OnDestroy {
  @Output() countdownStarted = new EventEmitter<void>();
  @Output() countdownEnded = new EventEmitter<void>();
  days = 0;
  hours = 0;
  minutes = 0;
  seconds = 0;
  imagePath: string;
  showCountdown = false;
  private countdownSubscription!: Subscription;
  currentTargetDate: number;

  // Banners
  private readonly initialBannerDesktop = 'assets/img/bf-banners/BlackFridayStartsInDesktop.jpg';
  private readonly initialBannerMobile = 'assets/img/bf-banners/midnightmidnight.jpg';
  private readonly secondBannerDesktop = 'assets/img/bf-banners/BlackFridayEndsInDesktop.jpg';
  private readonly secondBannerMobile = 'assets/img/bf-banners/BlackFridayEndsInMob.jpg';

  // Timestamps
  private readonly countdownStartDate = new Date('2024-11-14T23:55:00+04:00').getTime();
  private readonly firstTargetDate = new Date('2024-11-15T10:00:00+04:00').getTime();
  private readonly finalTargetDate = new Date('2024-11-15T13:00:00+04:00').getTime();

  constructor(private renderer: Renderer2) {
    this.currentTargetDate = this.firstTargetDate;
  }

  ngOnInit(): void {
    this.countdownSubscription = interval(1000).subscribe(() => {
      const now = new Date().getTime();

      if (now >= this.countdownStartDate && now < this.finalTargetDate) {
        if (!this.showCountdown) {
          this.showCountdown = true;
          this.countdownStarted.emit();
          this.hideHomeBanner();
        }
        this.updateImagePath();
        this.updateTime(this.currentTargetDate);
      } else if (now >= this.finalTargetDate) {
        // Hide everything when the final target date is reached
        this.showCountdown = false;
        this.imagePath = '';
        this.countdownEnded.emit();
        this.resetCountdown();
        this.unsubscribeCountdown();
      } else {
        this.showHomeBanner();
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeCountdown();
  }

  @HostListener('window:resize')
  onResize(): void {
    this.updateImagePath();
  }

  private updateImagePath(): void {
    const isMobile = window.innerWidth <= 768;
    const now = new Date().getTime();

    if (now >= this.countdownStartDate && now < this.firstTargetDate) {
      this.imagePath = isMobile ? this.initialBannerMobile : this.initialBannerDesktop;
    } else if (now >= this.firstTargetDate && now < this.finalTargetDate) {
      this.imagePath = isMobile ? this.secondBannerMobile : this.secondBannerDesktop;
    } else {
      this.imagePath = '';
    }
  }

  private updateTime(targetDate: number): void {
    const now = new Date().getTime();
    const timeLeft = targetDate - now;

    if (timeLeft > 0) {
      this.days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
    } else {
      // Switch to the next countdown phase
      if (this.currentTargetDate === this.firstTargetDate) {
        this.currentTargetDate = this.finalTargetDate;
        this.applyTimeUnitHighlight(); // Apply background color when switching to final target
      } else {
        this.resetCountdown();
      }
    }
  }

  private applyTimeUnitHighlight(): void {
    const timeUnits = document.getElementsByClassName('time-unit');
    Array.from(timeUnits).forEach((element) => {
      this.renderer.addClass(element, 'highlight-background');
    });
  }

  private removeTimeUnitHighlight(): void {
    const timeUnits = document.getElementsByClassName('time-unit');
    Array.from(timeUnits).forEach((element) => {
      this.renderer.removeClass(element, 'highlight-background');
    });
  }

  private showHomeBanner(): void {
    this.renderer.setStyle(document.getElementById('home-banner-container'), 'display', 'block');
  }

  private hideHomeBanner(): void {
    this.renderer.setStyle(document.getElementById('home-banner-container'), 'display', 'none');
  }

  private resetCountdown(): void {
    this.days = 0;
    this.hours = 0;
    this.minutes = 0;
    this.seconds = 0;
    this.removeTimeUnitHighlight();
  }

  private unsubscribeCountdown(): void {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
  }
}
