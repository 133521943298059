import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/shared/common.service';
import { CheckoutService } from './checkout.service';
import { appConfig } from '../../config/app-config';
import { Locale } from '../../config/locale';
import {
    CountryISO,
    PhoneNumberFormat,
    SearchCountryField,
} from '@capgo/ngx-intl-tel-input';
import { TranslateService } from '@ngx-translate/core';
import { EventService, EventType } from '../../utilities/event.service';
import { getUser } from '../../utilities/user.utils';
import { User } from '../../model/user.model';
import { CheckoutAddress } from './checkout.model';
import { CheckoutUtilsService } from './checkout.utils.service';
import { AppService } from 'src/app/app.service';
import { getBaseUrlMofluid } from 'src/app/utilities/url.utils';
import {
    CurrencyLanguageService,
    LocaleData,
} from 'src/app/shared/currency-language.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { screenSizes } from 'src/app/utilities/theme';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalOffcanvasComponent } from 'src/app/controls/modal-offcanvas/modal-offcanvas.component';
import { isFirefox, isSafari } from 'src/app/utilities/utils';

declare let $;
declare const gtag: Function;

@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit {
    conversion_rate: number = 0;
    reward_point_rate: number = 0;
    order_count: number = 0;
    showRewardPointErrorEle: boolean = false;
    isRewardPoint: boolean = false;
    isStorePoint: boolean = false;
    isGift: boolean = false;
    appConfig = appConfig;
    subscription: Subscription = new Subscription();
    guests = [];
    tempObj: any = {};
    giftCredit: any = {};
    redirectedUrl: any = '';
    redirect_id: any = '';
    currentProjectUrl: any = '';
    items: any = {};
    paymentRefrence: any = '';
    showBlackFriday: any = localStorage.getItem('is_promotion_started');
    blackFridayDiscount: any =
        'Enter Code "<strong>BLACKFRIDAY</strong>" on November 26';
    params: any;
    public shared_data: any;
    pId!: any;
    pUrl!: any;
    productid = this.pId;
    public pID: any;
    empty_chk: any = false;
    isGiftVoucherInCart: any = true;
    isGiftCardApplied: any = true;
    giftCardAppliedCount: any = 0;
    fullWindowHeight: any = window.innerHeight;
    checkout: any = {};
    age_box_id_start: any = 0;
    totalPrice: any = 0;
    reservation_data: any = {};
    subtotalPrice: any = 0;
    giftcard: any = 0;
    hidden_code: any = '';
    balance_format: any = 0;
    gift_input_val: any = 0;
    gift_discount: any = 0;
    gift_code: any = '';
    giftcardlabel: any = '';
    giftcardbalance: any = 0;
    changebalance: any = !1;
    gift_value_used: any = 0;
    rw_balance_money: number = 0;
    total_balance: any = 0;
    gift_card_credit_check_box: any = !1;
    checkoutFailureCount: any = 0;
    runFlag: any = 0;
    billingAddress: CheckoutAddress;
    shippingAddress: CheckoutAddress;
    public countryList = [{ country_id: '', country_name: 'Select' }];
    selectedCountry = { country_id: '', country_name: 'Select' };
    stateList: { region_id: string; region_name: any }[];
    localeTxt: any;
    localeAlert: any;
    myAddressStatus: any;
    displayMyAddressBar: any;
    mySelectedAddress: any;
    customerCards: any;
    MyAddress: any;
    applyiedGiftCode: any;
    existed_giftCodes: any;
    msg: any;
    cart_total_amount: any;
    coupon_code_discount: any;
    cart_order_total: any;
    discountCodeTitle: any;
    discountCode: any;
    isDiscountCodeApplied: boolean = false;
    discountCodeShow: boolean = false;
    email: any;
    initial_dialcode: any;
    customerCardInfo: any;
    product_quantity_count: Object;
    showstatus: any;
    loginSuccess: boolean;
    public loginResponse: User;
    catArrFromDataService: Subscription;
    public userLogin: any;
    public customerId: any = '';
    paymentList: any;
    rewardPts: any;
    rw_slider: any;
    rw_balance: any;
    slider_gap: any;
    slider_gap_money: any;
    user_giftCodes: any;
    advancesearch: any;
    platform: any;
    minimumtimer: any;
    payment_method_with_button_enabled: any;
    store_discount_amount: any;
    rw_discountAmount: any;
    usedRwPt: any;
    old_reward_value: any;
    credit_amount: any;
    data1: any;
    shownPaymentMethod: any;
    billAddress_status: any;
    sendCardInfo: any;
    place_order_url: any;
    max_gift_discount: any;
    storeLabel: any = {};
    id: any;
    value: any;
    cardId: any;
    newcard: number;
    savecard: string;
    cvv: any;
    cardToken: any;
    cardFrameInitiated: boolean;
    userName: string;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
    reunionGeneral: boolean = false;
    language: any;
    localeData: LocaleData;

    showCartSkeleton: boolean = false;
    showPaymentMethods: boolean = false;
    mipsIframeLoading: boolean = false;
    @ViewChild('modalOffcanvas') modalOffcanvas: ModalOffcanvasComponent;
    isSafari: boolean = isSafari();
    isFirefox: boolean = isFirefox();

    constructor(
        public commonService: CommonService,
        public checkoutUtilsService: CheckoutUtilsService,
        private eventService: EventService,
        private checkService: CheckoutService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private translate: TranslateService,
        private localeObj: Locale,
        private appService: AppService,
        private currencyLanguageService: CurrencyLanguageService,
        private modalService: NgbModal,
        private spinnerService: NgxSpinnerService
    ) {
        this.checkout.service_fee = 0;
        this.localeTxt = this.localeObj.getTextMessages();
        this.localeAlert = this.localeObj.getAlertMessages();
        let userdata = getUser();
        if (!userdata) {
            this.login();
            return;
        }
        this.subscription = this.commonService.currentData.subscribe(
            (data: any) => {
                this.shared_data = data;
            }
        );
        this.subscription = this.currencyLanguageService
            .getLocaleData()
            .subscribe((localeData: LocaleData) => {
                this.localeData = localeData;
            });

        this.activatedRoute.queryParams.subscribe((params: any) => {
            this.params = params;
            this.pId = this.pID = this.params.productId;
            this.pUrl = this.params.canonical_url_last;
        });

        if (userdata) {
            this.loginResponse = userdata;
            this.customerId = this.loginResponse.id;
            this.userName = this.loginResponse.username;
        }

        this.updateCostsBasedOnResponse =
            this.updateCostsBasedOnResponse.bind(this);
        this.uncheckGiftAndApplyStoreCredit =
            this.uncheckGiftAndApplyStoreCredit.bind(this);
        this.switchPayment = this.switchPayment.bind(this);
    }

    cartItems: any;
    @ViewChild('inputFocus') inputFocus;

    offsetNew(el: any) {
        let rect = $(el)[0].getBoundingClientRect();
        let scrollLeft =
            window.pageXOffset || document.documentElement.scrollLeft;
        let scrollTop =
            window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    }

    moveToGuestNameDiv() {
        let div = document.querySelector('.chkot-input-border_empty');
        let divOffset = this.offsetNew(div);
        let top = divOffset.top - 100;
        window.scrollTo(divOffset.left, top);
        $('.chkot-input-border_empty').addClass('important');
    }

    ngOnInit(): void {
        this.checkService.fetchCountries().subscribe((response: any) => {
            for (let country in response) {
                this.countryList.push({
                    country_id: country,
                    country_name: response[country],
                });
            }
        });

        $(':input').on('keyup change', (e: any) => {
            if (e.target.name != 'paymentRadioGroup') {
                if (
                    this.checkout.slctdPymntMthd == 'mipspaymentsystemiframe' ||
                    this.checkout.slctdPymntMthd == ''
                ) {
                }
                $('#' + e.target.id).focus();
            }
        });
        this.shared_data.page_title = 'Checkout';
        $(document).ready(() => {
            $('i').append(
                '<style>.checkbox-square .checkbox-icon:after{top:3px !important;width: 9px!important;height: 6px!important;}</style>'
            );
            $('i').append(
                '<style>.checkbox-square .checkbox-icon:before{border-color:#257ba3!important}</style>'
            );
            $('html').bind('keypress', (e: any) => {
                if (e.keyCode == 13) {
                    $('input').blur();
                    return false;
                }
            });
            $(window).resize(() => {
                if (window.innerHeight == this.fullWindowHeight) {
                    $('input').blur();
                }
            });
        });
        let userData = getUser();
        if (userData) {
            this.email = userData.email
                ? userData.email
                : userData.username || '';
        }
        this.billingAddress = {
            defaultAddress: 1,
            firstname: '',
            lastname: '',
            phone: '',
            address: '',
            city: '',
            country: 'MU',
            state: '',
            stateCustom: '',
            zipcode: '',
            email: this.email,
        };
        this.shippingAddress = this.billingAddress;
        this.stateList = [
            {
                region_id: '',
                region_name: this.localeTxt.select,
            },
        ];
        this.billingAddress.state = {
            region_id: '',
            region_name: this.localeTxt.select,
        };
        this.shippingAddress.state = {
            region_id: '',
            region_name: this.localeTxt.select,
        };
        this.myAddressStatus = !1;
        this.displayMyAddressBar = !1;
        this.mySelectedAddress = '';
        this.customerCards = {};
        let address = {};
        this.MyAddress = address;
        this.applyiedGiftCode = [];
        this.existed_giftCodes = [];
        this.getAllCartItem();
        $('ion-pane.home-content.menu-content.pane').scrollTop(0);
        this.discountCodeShow = !1;
        this.isDiscountCodeApplied = false;
        this.discountCode = '';
        this.store_discount_amount = 0;
        this.store_credit();
        this.cart_total_amount = 0;
        this.coupon_code_discount = 0;
        this.cart_order_total = '0';
        let urlParams: any = this.getUrllets();
        let callfrom = urlParams.callfrom ? urlParams.callfrom : '';
        let payment_orderid: any;
        if (callfrom == 'payment') {
            payment_orderid = urlParams.orderid;
            let payment_OrderStatus = urlParams.OrderStatus;
            let payment_amount = urlParams.payment_amount;
            this.commonService.startProgress();
            let billing = this.commonService.getCache(this, 'billing_address');
            this.checkout.slctdPymntMthd = 'ipay';
            if (this.checkout.slctdPymntMthd == 'ipay') {
                this.processCreditCardPaymentInvoice(
                    payment_orderid,
                    billing,
                    payment_amount,
                    payment_OrderStatus,
                    'ipay'
                );
            }
            this.router
                .navigate(['/invoice'], {
                    queryParams: {},
                })
                .then(() => {
                    window.location.reload();
                });
        }
        this.usedRwPt = 0;
        this.old_reward_value = 0;
        this.rw_discountAmount = 0;
        let cachedData = this.commonService.getCache(this, 'cartItemsdata');
        if (cachedData) {
            this.cartItems = this.checkvalidcartItems(cachedData.cart_products);
            this.getCustomerAddress();
            this.get_gift_card_credit();
            this.items = this.cartItems;
            this.calculatePrice();
            this.getAvlbPaymentMthds();
            this.calculateRewardPoint();
        }

        if (localStorage.getItem('marideal-pwa_customer_group_id')) {
            let customer_id = JSON.parse(
                localStorage.getItem('marideal-pwa_customer_group_id')
            ).data;
            if (customer_id == 8) {
                this.reunionGeneral = true;
            }
        }
    }

    //End of NgOnInit

    validateMipsCredit() {
        if (
            this.checkout.slctdPymntMthd == 'mipspaymentsystemiframe' ||
            this.checkout.slctdPymntMthd == ''
        ) {
        }
    }
    allotment_check_mdpErrorMsg: any;
    cart_items_qty: any;

    getAllCartItem() {
        let checkoutLoadingMessage =
            '<ion-spinner class="app-loader" icon="spiral"></ion-spinner>';

        /**** letiable to set the skeleton ****/
        this.showCartSkeleton = true;
        this.checkService.getAllCartItems().subscribe(
            (response: any) => {
                this.showCartSkeleton = false;
                if (response.status == 'success') {
                    this.conversion_rate = Number(response.conversion_rate);
                    this.reward_point_rate = Number(response.reward_point_rate);
                    this.order_count = parseInt(response.order_count);
                    this.showRewardPointErrorEle =
                        this.order_count < 1 ? true : false;
                    let couponCode = response.coupon_code;
                    this.cart_total_amount = parseFloat(
                        response.cart_total_amount
                    );
                    this.coupon_code_discount = parseFloat(
                        response.coupon_code_discount
                    );
                    this.cart_order_total = response.cart_order_total;
                    this.discountCodeTitle = response.coupon_code_title;
                    this.allotment_check_mdpErrorMsg =
                        response.allotment_check_mdpErrorMsg;
                    this.cart_items_qty = response.cart_items_qty;

                    if (
                        this.allotment_check_mdpErrorMsg &&
                        this.allotment_check_mdpErrorMsg.length > 0
                    ) {
                        this.openValidationModal(
                            response.allotment_check_mdpErrorMsg
                        );
                    }

                    if (couponCode) {
                        if (couponCode.length > 0) {
                            this.discountCode = couponCode;
                            this.isDiscountCodeApplied = true;
                            this.discountCodeShow = true;
                        }
                    }
                    setTimeout(() => {
                        this.items = response.cart_products;
                        if (this.items.length == 0) {
                            this.empty_chk = true;
                            $('form').css({
                                'background-color': '#f7f7f7',
                            });
                        } else
                            $('form').css({
                                'background-color': '#fff',
                            });
                        //store in cache
                        for (let i = 0; i < this.items.length; i++) {
                            this.items[i].quantity_arr =
                                this.checkoutUtilsService.create_quantity_array(
                                    this.items[i].max_qty
                                );
                        }
                        localStorage.setItem('quoteid', response.quoteid);
                        this.commonService.setCache(
                            this,
                            'cartItemsdataCount',
                            response.cart_items_qty
                        );
                        this.commonService.setCache(
                            this,
                            'cartItemsdata',
                            response
                        );
                        checkoutLoadingMessage =
                            '<ion-spinner class="app-loader" icon="spiral"></ion-spinner>';
                        this.runFlag = 1;
                        this.getCustomerAddress();
                        this.calculatePrice();
                        this.get_gift_card_credit();
                        setTimeout(() => {
                            this.getAvlbPaymentMthds();
                        }, 1000);
                        this.calculateRewardPoint();
                    });
                } else {
                    this.openValidationModal(response.msg);
                }
            },
            (err: any) => {
                this.openValidationModal(
                    this.localeAlert.server_not_responding
                );
            }
        );
    }

    dialcode: any;

    getCustomerAddress() {
        let profileUrl =
            this.commonService.api_url +
            'myprofile?service=myprofile&customerid=' +
            this.customerId +
            '&customer_group_id=' +
            this.commonService.getCustomerGroupID();
        if (this.runFlag == 1) {
            this.checkService.serviceMethod(profileUrl).subscribe(
                (response: any) => {
                    if (
                        response != undefined &&
                        response.status != undefined &&
                        response.status == -1
                    ) {
                        this.commonService.logout();
                        this.commonService.startProgress();
                        this.openValidationModal(response.message);
                        this.commonService.endProgress();
                    }
                    let mobileNo = '';
                    if (Object.keys(response.BillingAddress).length) {
                        let billingAddress = response.BillingAddress;

                        this.selectedCountry = this.countryList.filter((x) => {
                            if (x.country_id == billingAddress.countryid) {
                                this.billingAddress.country = x.country_id;
                                return x;
                            }
                        })[0];

                        this.shippingAddress.country =
                            this.billingAddress.country;
                        let billingdefaultState = '';
                        billingAddress.country = {
                            country_id: billingAddress.countryid,
                        };

                        this.billingAddress = {
                            defaultAddress: 1,
                            firstname: billingAddress.firstname,
                            lastname: billingAddress.lastname,
                            address: billingAddress.street,
                            country: billingAddress.countryid,
                            state: billingdefaultState,
                            stateCustom: billingAddress.region,
                            city: billingAddress.city,
                            zipcode: billingAddress.pincode,
                            phone: billingAddress.contactno,
                            email: this.email,
                        };
                        setTimeout(() => {
                            this.dialcode = this.getcountryCodeForMob();
                            let mob = billingAddress.contactno.replace(
                                this.dialcode,
                                ''
                            );
                            this.billingAddress.phone = mob;
                        }, 500);

                        mobileNo = billingAddress.contactno;
                        this.myAddressStatus = !1;
                        this.displayMyAddressBar = !0;
                        this.mySelectedAddress =
                            billingAddress.firstname +
                            ',' +
                            billingAddress.lastname +
                            ',' +
                            billingAddress.contactno +
                            ',,' +
                            ',Mauritius';
                    } else {
                        let userData = getUser();
                        if (userData) {
                            this.billingAddress = {
                                defaultAddress: 1,
                                firstname: userData.firstName,
                                lastname: userData.lastName,
                                address: '',
                                country: this.selectedCountry.country_id,
                                state: '',
                                stateCustom: '',
                                city: '',
                                zipcode: '',
                                phone: userData.telephone,
                                email: this.email,
                            };
                            setTimeout(() => {
                                this.dialcode = this.getcountryCodeForMob();
                                let mob = userData.telephone.replace(
                                    this.dialcode,
                                    ''
                                );
                                this.billingAddress.phone = mob;
                            }, 500);

                            mobileNo = userData.telephone;
                        }
                    }
                    if (
                        response &&
                        response.CheckoutPaymentDetails &&
                        response.CheckoutPaymentDetails.status
                    ) {
                        this.customerCardInfo =
                            response.CheckoutPaymentDetails.customer;
                    }
                    this.shippingAddress = this.billingAddress;
                    this.MyAddress.billing = this.billingAddress;
                    this.MyAddress.shipping = this.shippingAddress;
                    this.MyAddress = this.MyAddress;
                    //home removed
                    let cachedData = this.commonService.getCache(
                        this,
                        'cartItemsdata'
                    );
                    if (cachedData) {
                        this.guests = [];
                        this.isGiftVoucherInCart = !1;
                        let cartItems = this.checkvalidcartItems(
                            cachedData.data.cart_products
                        );
                        let product_quantity_count = new Object();
                        for (let i = 0; i < cartItems.length; i++) {
                            if (cartItems[i].type != 'giftvoucher') {
                                this.guests[i] = [];
                                for (
                                    let j = 0;
                                    j < cachedData.data.cart_products[i].qty;
                                    j++
                                ) {
                                    if (
                                        product_quantity_count[
                                            cartItems[i].id
                                        ] == undefined
                                    ) {
                                        product_quantity_count[
                                            cartItems[i].id
                                        ] = 1;
                                    } else {
                                        product_quantity_count[
                                            cartItems[i].id
                                        ] =
                                            product_quantity_count[
                                                cartItems[i].id
                                            ] + 1;
                                    }
                                    //create structguestoption
                                    let guestItem = [];
                                    let itemDesc = {
                                        description: cartItems[i].description,
                                    };
                                    for (
                                        let k = 0;
                                        k < cartItems[i].options.length;
                                        k++
                                    ) {
                                        let structguestCustomOption = {
                                            ...cartItems[i].options[k],
                                            ...itemDesc,
                                        };
                                        guestItem.push(structguestCustomOption);
                                    }
                                    let category_type = '';
                                    if (cartItems[i].cat_id.length > 1) {
                                        if (
                                            cartItems[i].cat_id[0] == 9 ||
                                            cartItems[i].cat_id[0] == '9'
                                        ) {
                                            if (
                                                cartItems[i].cat_id[1] == 16 ||
                                                cartItems[i].cat_id[1] ==
                                                    '16' ||
                                                cartItems[i].cat_id[1] == 17 ||
                                                cartItems[i].cat_id[1] == '17'
                                            ) {
                                                category_type = 'Hotels';
                                            }
                                        } else if (
                                            cartItems[i].cat_id[0] == '3' ||
                                            cartItems[i].cat_id[0] == '3'
                                        ) {
                                            category_type = 'Hotels';
                                        }
                                    } else {
                                        if (
                                            cartItems[i].cat_id == 3 ||
                                            cartItems[i].cat_id == '3'
                                        ) {
                                            category_type = 'Hotels';
                                        }
                                    }
                                    this.guests[i].push({
                                        quote_id: cartItems[i].cart_item_id,
                                        name:
                                            product_quantity_count[
                                                cartItems[i].id
                                            ] == 1
                                                ? this.billingAddress
                                                      .firstname +
                                                  ' ' +
                                                  this.billingAddress.lastname
                                                : '',
                                        email:
                                            product_quantity_count[
                                                cartItems[i].id
                                            ] == 1
                                                ? this.email
                                                : '',
                                        id: cartItems[i].id,
                                        subItem: j,
                                        guestrequest: '',
                                        remove_price: !1,
                                        category_type: category_type,
                                        product_name: cartItems[i].name,
                                        chk_in_date: cartItems[i].check_in,
                                        chk_out_date: cartItems[i].check_out,
                                        nights: cartItems[i].nights,
                                        item: guestItem,
                                        room_count:
                                            product_quantity_count[
                                                cartItems[i].id
                                            ],
                                        child_range: this.get_desc(
                                            cartItems[i]
                                        ),
                                        isempty: !1,
                                        categoryproductId: cartItems[i].cat_id,
                                        guest_id: 'guest_' + i,
                                        product_addon_data:
                                            cartItems[i].product_addon_data,
                                        // agency: cartItems[i].agency,
                                        // bookedticketdata:cartItems[i].bookedticketdata
                                    });
                                }
                            }
                            if (cartItems[i].type == 'giftvoucher') {
                                this.isGiftVoucherInCart = !0;
                            }
                        }
                        this.checkoutUtilsService.isGuestNameFilled = !1;
                        this.product_quantity_count = product_quantity_count;
                        this.checkoutUtilsService.validate_guest(
                            this.guests,
                            false
                        );
                    }
                },
                () => {
                    this.openValidationModal(
                        this.localeAlert.server_not_responding
                    );
                }
            );
        }
    }

    calculatePrice() {
        let total = parseFloat(this.cart_order_total);
        this.totalPrice = parseFloat(total.toFixed(2));
        this.subtotalPrice = parseFloat(total.toFixed(2));
        this.totalPrice =
            this.subtotalPrice -
            this.store_discount_amount -
            this.rw_discountAmount -
            this.gift_discount -
            this.gift_value_used;
    }

    get_gift_card_credit() {
        // this.commonService.startProgress();
        let gift_card_credit_url =
            this.commonService.api_url +
            'getGiftCardTotalBalance?store=' +
            this.commonService.getStoreID() +
            '&service=getGiftCardTotalBalance&customerid=' +
            this.customerId +
            '&currency=MUR' +
            '&customer_group_id=' +
            this.commonService.getCustomerGroupID();
        this.checkService.serviceMethod(gift_card_credit_url).subscribe(
            (response: any) => {
                if (response) {
                    this.giftcardlabel = response.giftCardDetails.label;
                    this.giftcardbalance = response.giftCardDetails.balance;
                    this.total_balance = response.giftCardDetails.balance;
                    this.showstatus = response.giftCardDetails.showStatus;
                }
            },
            () => {
                this.openValidationModal(
                    this.localeAlert.server_not_responding
                );
            }
        );
    }

    getAvlbPaymentMthds() {
        // this.commonService.startProgress();
        let paymentMthdUrl =
            this.commonService.api_url +
            'getpaymentmethod?service=getpaymentmethod&customerid=' +
            this.customerId +
            '&ismips=1' +
            '&customer_group_id=' +
            this.commonService.getCustomerGroupID();
        /**** letiable used to show the payment method skeleton ****/
        this.checkoutUtilsService.showGetPaymentMethodSkeleton = true;
        if (this.runFlag == 1) {
            this.checkService.serviceMethod(paymentMthdUrl).subscribe(
                (response: any) => {
                    this.paymentList = response;
                    if (response.status != undefined && response.status == -1) {
                        this.openValidationModal(response.message);
                        this.commonService.logout();
                        this.router.navigate(['/']);
                    }
                    /**** letiable used to show the payment method skeleton ****/
                    this.checkoutUtilsService.showGetPaymentMethodSkeleton =
                        false;
                    let receivedPaymentMethods = this.paymentList;
                    let creditCardSet = !1;
                    for (let receivedPaymentMethod in receivedPaymentMethods) {
                        if (
                            receivedPaymentMethods[receivedPaymentMethod]
                                .payment_method_newStatus == '1' &&
                            !this.checkout.slctdPymntMthd
                        ) {
                            if (
                                this.checkout.slctdPymntMthd ==
                                'mipspaymentsystemiframe'
                            ) {
                                this.getPaymentMethod(
                                    receivedPaymentMethods[
                                        receivedPaymentMethod
                                    ]
                                );
                                creditCardSet = !0;
                            }
                        } else {
                            if (
                                this.checkout.slctdPymntMthd ==
                                    'mipspaymentsystemiframe' &&
                                !creditCardSet
                            ) {
                                this.callOnRemoveItem(
                                    receivedPaymentMethods[
                                        receivedPaymentMethod
                                    ],
                                    true
                                );
                                creditCardSet = !0;
                            }
                        }
                    }
                },
                (err: any) => {
                    this.openValidationModal(
                        this.localeAlert.server_not_responding
                    );
                }
            );
        }
    }

    totalRPValue: number = 0;

    calculateRewardPoint() {
        let quoteid = localStorage.getItem('quoteid');
        let rewardPtUrl =
            this.commonService.api_url +
            'preparequote?service=preparequote&customerid=' +
            this.customerId +
            '&quoteid=' +
            quoteid +
            '&address=&products=' +
            '' +
            '&is_create_quote=0&find_shipping=&subtotal_amount=' +
            '' +
            '&giftCardProducts=' +
            '&customer_group_id=' +
            this.commonService.getCustomerGroupID();
        if (this.runFlag == 1) {
            this.checkService.serviceMethod(rewardPtUrl).subscribe(
                (response: any) => {
                    this.commonService.endProgress();
                    if (response) {
                        this.rewardPts = response.earning_points
                            ? response.earning_points
                            : 0;
                        this.calculateRPForReunionOrNot();
                        this.rw_slider = response.slider ? response.slider : '';
                        this.rw_balance = this.rw_slider
                            ? this.rw_slider.balance_points
                            : '';
                        this.slider_gap = this.rw_slider
                            ? this.rw_slider.slider_gap
                            : '';
                        this.slider_gap_money = this.rw_slider
                            ? this.rw_slider.slider_gap_money
                            : '';

                        let is_reunion = this.commonService.getCache(
                            this,
                            'is_reunion'
                        )
                            ? this.commonService.getCache(this, 'is_reunion')
                                  .data
                            : false;
                        let totalRPValue: number = 0;

                        if (is_reunion) {
                            totalRPValue =
                                (this.rw_slider.balance_points /
                                    this.reward_point_rate) *
                                this.conversion_rate;
                        } else {
                            totalRPValue =
                                this.rw_slider.balance_points /
                                this.reward_point_rate;
                        }

                        this.totalRPValue =
                            this.commonService.convertPriceToEuroFormat(
                                totalRPValue
                            );
                        this.rw_balance_money =
                            (this.rw_balance / this.slider_gap) *
                            this.slider_gap_money;
                        this.rw_balance_money = Number(
                            this.rw_balance_money.toString().split('.')[0]
                        );
                        this.user_giftCodes = response.existed_giftCodes
                            ? response.existed_giftCodes
                            : [];
                        this.existed_giftCodes = this.user_giftCodes.filter(
                            (ar) =>
                                !this.applyiedGiftCode.find(
                                    (rm) => rm.gift_code === ar.gift_code
                                )
                        );
                    }
                },
                () => {
                    this.openValidationModal(
                        this.localeAlert.server_not_responding
                    );
                }
            );
        }
    }

    get_desc(desc: any) {
        let child_map = [];
        for (let i = 0; i < desc.children_formdata.length; i++) {
            let object_id = {};
            let child_wise_desc = [];
            for (
                let child_count = 0;
                child_count < desc.children_formdata[i].qty;
                child_count++
            ) {
                child_wise_desc.push({
                    count: child_count,
                    starting_range: desc.children_formdata[i].min,
                    end_range: desc.children_formdata[i].max,
                    entered_date: '',
                    status: 'full',
                    id: 'age_box_' + this.age_box_id_start,
                });
                this.age_box_id_start++;
            }
            object_id[desc.children_formdata[i].child_type] = child_wise_desc;
            child_map.push(object_id);
        }
        return child_map;
    }

    checkvalidcartItems(mycartItems: any = []) {
        let newcartItems = [];
        this.minimumtimer = 1000000;
        let itemchanged = false;
        let totalCartItems = 0;
        let temptime: any;
        for (let i = 0; i < mycartItems.length; i++) {
            if (mycartItems[i].productstoretime) {
                temptime = Date.now() - mycartItems[i].productstoretime;
                let timerlimit = mycartItems[i].timer * 60 * 1000;
                let remainingtime = timerlimit - temptime;
                if (remainingtime <= 0) {
                    itemchanged = true;
                    continue;
                } else if (this.minimumtimer > remainingtime) {
                    this.minimumtimer = remainingtime;
                }
            }
            totalCartItems++;
            newcartItems.push(mycartItems[i]);
        }
        if (itemchanged) {
            let items = {
                items: newcartItems,
            };
            if (totalCartItems > 0) {
                this.commonService.setCache(this, 'cartItems', items);
                this.commonService.setCache(
                    this,
                    'cartItemsCount',
                    totalCartItems
                );
            } else {
                this.commonService.removeCache(this, 'cartItems');
                this.commonService.setCache(this, 'cartItemsCount', 0);
            }
            this.router
                .navigate(['/cart'], {
                    queryParams: {},
                })
                .then(() => {
                    window.location.reload();
                });
            this.openValidationModal('Item has been removed from cart');
            return newcartItems;
        }
        if (this.minimumtimer > 0 && this.minimumtimer != 1000000) {
            if (!this.shared_data.timeoutletiable) {
                this.shared_data.timeoutletiable = [];
            }
            setTimeout(() => {
                this.router
                    .navigate(['/cart'], {
                        queryParams: {},
                    })
                    .then(() => {
                        window.location.reload();
                    });
            }, this.minimumtimer);
            this.shared_data.timeoutletiable.push(temptime);
        }
        return newcartItems;
    }

    servicFeeVal: number = 0;

    calculateTotalViaServiceFee() {
        this.checkout.service_fee = Number(this.checkout.service_fee);
        if (typeof this.checkout.service_fee == 'number') {
            this.servicFeeVal =
                this.totalPrice * (this.checkout.service_fee / 100);
            this.totalPrice = this.servicFeeVal + this.totalPrice;
        }
    }

    callOnRemoveItem(paymentMethod: any, isBlurElement: any) {
        if (isBlurElement) {
            $('input').blur();
            $('#cout_qoestion_input').blur();
        }
        this.checkout.slctdPymntMthd = paymentMethod.payment_method_code;
        this.checkout.service_fee = paymentMethod.payment_method_service_fee;
        this.payment_method_with_button_enabled =
            paymentMethod.payment_method_with_button_enabled;
        if (paymentMethod && paymentMethod.payment_method_account_key) {
            this.checkout.paymentmethodKey =
                paymentMethod.payment_method_account_key;
        }
        this.calculatePrice();
        this.calculateTotalViaServiceFee();
        this.calculateRPForReunionOrNot();
        setTimeout(() => {
            this.reloadMipsPaymentCreditCardView();
        }, 100);
    }

    private scrollElementToTop(elementId: string) {
        let element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    getPaymentMethod(paymentMethod: any, elementToScrollInViewId?: string) {
        this.mipsIframeLoading = false;
        if (elementToScrollInViewId) {
            this.scrollElementToTop(elementToScrollInViewId);
        }

        this.checkout.slctdPymntMthd = paymentMethod.payment_method_code;
        this.checkout.service_fee = paymentMethod.payment_method_service_fee;
        this.payment_method_with_button_enabled =
            paymentMethod.payment_method_with_button_enabled;
        if (paymentMethod && paymentMethod.payment_method_account_key) {
            this.checkout.paymentmethodKey =
                paymentMethod.payment_method_account_key;
        }
        this.calculatePrice();
        this.calculateTotalViaServiceFee();
        this.calculateRPForReunionOrNot();
        setTimeout(() => {
            if (this.checkout.slctdPymntMthd == 'mipspaymentsystemiframe') {
                this.mipsIframeLoading = true;
                let quoteid = !localStorage.getItem('quoteid')
                    ? null
                    : localStorage.getItem('quoteid');
                if (quoteid == null) {
                    let quoteid = '';
                }
                let getCartItem =
                    this.commonService.api_url +
                    'getAllCartItems?store=' +
                    this.commonService.getStoreID() +
                    '&currency=MUR&service=getAllCartItems&customerid=' +
                    this.customerId +
                    '&quoteid=' +
                    quoteid +
                    '&customer_group_id=' +
                    this.commonService.getCustomerGroupID();
                this.checkService.serviceMethod(getCartItem).subscribe(
                    (response: any) => {
                        this.showCartSkeleton = false;
                        if (response.status == 'success') {
                            this.conversion_rate = Number(
                                response.conversion_rate
                            );
                            this.reward_point_rate = Number(
                                response.reward_point_rate
                            );
                            this.order_count = parseInt(response.order_count);
                            this.showRewardPointErrorEle =
                                this.order_count < 1 ? true : false;
                            let couponCode = response.coupon_code;
                            this.cart_total_amount = parseFloat(
                                response.cart_total_amount
                            );
                            this.coupon_code_discount = parseFloat(
                                response.coupon_code_discount
                            );
                            this.cart_order_total = response.cart_order_total;
                            this.discountCodeTitle = response.coupon_code_title;
                            this.allotment_check_mdpErrorMsg =
                                response.allotment_check_mdpErrorMsg;

                            if (
                                this.allotment_check_mdpErrorMsg &&
                                this.allotment_check_mdpErrorMsg.length > 0
                            ) {
                                this.openValidationModal(
                                    response.allotment_check_mdpErrorMsg
                                );
                            } else {
                                setTimeout(() => {
                                    this.reloadMipsPaymentCreditCardView();
                                    this.mipsIframeLoading = false;
                                }, 2000);
                            }
                        } else {
                            this.openValidationModal(response.msg);
                        }
                    },
                    () => {
                        this.openValidationModal(
                            this.localeAlert.server_not_responding
                        );
                    }
                );
            }
        }, 100);
    }

    gift_card_credit_apply() {
        if (this.totalPrice >= this.gift_value_used) {
            this.totalPrice = this.totalPrice - this.gift_value_used;
        } else {
            let remaining_gift_amount = this.gift_value_used - this.totalPrice;
            if (remaining_gift_amount < this.store_discount_amount) {
                this.store_discount_amount -= remaining_gift_amount;
            } else {
                let unused_gift_card_amount =
                    this.gift_value_used -
                    (this.totalPrice + this.store_discount_amount);
                if (unused_gift_card_amount >= 0) {
                    this.totalPrice = 0;
                    (this.gift_value_used -= unused_gift_card_amount), 10;
                } else {
                    this.totalPrice -=
                        this.gift_value_used - this.store_discount_amount;
                }
                this.store_discount_amount = 0;
            }
        }
        this.calculateTotal();
    }

    applyRewardPoints() {
        this.commonService.startProgress();
        this.checkService
            .getRewardsPrice(
                this.usedRwPt,
                this.cart_total_amount,
                this.gift_discount,
                this.coupon_code_discount,
                this.gift_value_used,
                this.store_discount_amount
            )
            .subscribe({
                next: (response: any) => {
                    this.commonService.endProgress();
                    if (
                        response &&
                        response.amount <=
                            this.totalPrice + this.rw_discountAmount
                    ) {
                        this.old_reward_value = this.usedRwPt;
                        this.rewardPts = response.earning_points
                            ? response.earning_points
                            : 0;
                        this.calculateRPForReunionOrNot();
                        this.rw_discountAmount = response.amount
                            ? response.amount
                            : 0;
                        this.rw_discountAmount = Number(this.rw_discountAmount);
                        this.rw_discountAmount =
                            this.rw_discountAmount >
                            this.totalPrice + this.rw_discountAmount
                                ? this.totalPrice + this.rw_discountAmount
                                : this.rw_discountAmount;
                        this.discount_validation('reward');
                        this.calculateTotal();
                        this.old_reward_value = this.usedRwPt;
                    } else {
                        this.openValidationModal(
                            'You have no enough payble balance to apply selected Reward Points'
                        );
                        $('.chk-rewards-item-syp-input').val(
                            this.old_reward_value
                        );
                        this.usedRwPt = this.old_reward_value;
                    }
                },
                error: () => {
                    this.commonService.endProgress();
                    this.openValidationModal(
                        this.localeAlert.server_not_responding
                    );
                },
            });
    }

    discount_validation(type: any) {
        if (type == 'gift') {
            this.gift_discount = this.data1.gift_input_val;
            this.totalPrice =
                this.subtotalPrice -
                this.gift_discount -
                this.rw_discountAmount;
        } else if (type == 'reward') {
            this.totalPrice =
                this.subtotalPrice -
                this.gift_discount -
                this.rw_discountAmount;
        } else if (type == 'store') {
            if (this.totalPrice < this.credit_amount) {
                this.store_discount_amount = this.totalPrice;
                this.totalPrice = this.totalPrice - this.store_discount_amount;
            } else {
                this.store_discount_amount = this.credit_amount;
                this.totalPrice = this.totalPrice - this.store_discount_amount;
            }
        }
        this.calculateTotal();
    }

    calculateTotal() {
        if (!this.isRewardPoint) {
            this.rw_discountAmount = 0;
            this.usedRwPt = 0;
        }

        if (this.store_discount_amount <= 0) {
            $('input[type=checkbox][value="StoreCredit"]').prop('checked', 0);
        }

        this.totalPrice =
            this.subtotalPrice -
            this.store_discount_amount -
            this.rw_discountAmount -
            this.gift_discount -
            this.gift_value_used;
        if (this.totalPrice < 0) {
            this.totalPrice = 0;
        }
        if (parseInt(this.totalPrice) == 0) {
            this.checkout.slctdPymntMthd = 'free';
            this.payment_method_with_button_enabled = undefined;
        } else if (
            this.checkout.slctdPymntMthd == 'free' &&
            this.paymentList.length > 0
        ) {
            this.checkout.selectedPaymentMethod =
                this.paymentList[0].payment_method_id;
            this.checkout.slctdPymntMthd =
                this.paymentList[0].payment_method_code;
        }
        if (this.checkout.slctdPymntMthd == 'mipspaymentsystemiframe') {
            let output = this.paymentList.filter(
                (obj) => obj.payment_method_code == 'mipspaymentsystemiframe'
            );
            if (output.length > 1) {
                this.callOnRemoveItem(output[0], true);
            }
        }
        this.removeServiceFee();
    }

    /**
     * Method to calculate RP for reunion and not reunion customers
     */

    calculateRPForReunionOrNot() {
        let is_reunion = this.commonService.getCache(this, 'is_reunion')
            ? this.commonService.getCache(this, 'is_reunion').data
            : false;
        if (is_reunion && this.checkout.service_fee > 0) {
            // Reunion customer with service fee included
            this.rewardPts =
                (this.totalPrice +
                    parseFloat(this.store_discount_amount) -
                    this.servicFeeVal) /
                this.conversion_rate /
                this.reward_point_rate;
        } else if (is_reunion && this.checkout.service_fee == 0) {
            // Reunion customer with service fee not included
            this.rewardPts =
                (this.totalPrice + parseFloat(this.store_discount_amount)) /
                this.conversion_rate /
                this.reward_point_rate;
        } else if (this.checkout.service_fee > 0) {
            // Not a reunion customer with service > 0
            this.rewardPts =
                (this.totalPrice +
                    parseFloat(this.store_discount_amount) -
                    this.servicFeeVal) /
                this.reward_point_rate;
        } else {
            // Not a reunion customer with service fee 0
            this.rewardPts =
                (this.totalPrice + parseFloat(this.store_discount_amount)) /
                this.reward_point_rate;
        }
    }

    change_gift_card_balance() {
        this.changebalance = this.changebalance ? !1 : !0;
    }

    createReservationData() {
        this.reservation_data = {};
        this.guests.forEach((value: any, key: any) => {
            let final_obj = {};
            let quantity_count = 0;
            let cart_id = '';
            let outer_value: any;
            value.forEach((value2: any, key2: any) => {
                cart_id = value2.quote_id;
                if (value2.remove_price == true) {
                    outer_value = {
                        quote_id: value2.quote_id,
                        guest_name: value2.name,
                        guest_email: value2.email,
                        remove_price: 'on',
                        special_request_comment: value2.guestrequest,
                    };
                } else {
                    outer_value = {
                        quote_id: value2.quote_id,
                        guest_name: value2.name,
                        guest_email: value2.email,
                        special_request_comment: value2.guestrequest,
                    };
                }
                let child_final = {};
                let child_data1 = {};
                let child_data_inner = {};
                let ct = 1;
                let child_count = 1;
                for (let k = 0; k < value2.child_range.length; k++) {
                    let val = value2.child_range[k];
                    for (let child in val) {
                        if (child != '$$hashKey') {
                            let child_key = {};
                            let child_range = {};
                            let child_arr = [];
                            let child_inner_keys = {};
                            let count = 1;
                            for (let inner_val in val[child]) {
                                child_range = {
                                    child_serial_number: child_count,
                                    child_label: child,
                                    child_age_range:
                                        val[child][inner_val].starting_range +
                                        '-' +
                                        val[child][inner_val].end_range,
                                    child_age:
                                        val[child][inner_val].entered_date,
                                };
                                child_count++;
                                child_inner_keys[count] = child_range;
                                count++;
                            }
                            child_key['child'] = child_inner_keys;
                            child_final[ct] = child_key;
                            ct++;
                        }
                    }
                }
                let quantity_obj: any;
                if (Object.entries(child_final).length != 0) {
                    child_data_inner[0] = child_final;
                    child_data1['child_data'] = child_data_inner;
                    quantity_obj = { ...outer_value, ...child_data1 };
                } else {
                    quantity_obj = { ...outer_value };
                }
                final_obj[quantity_count] = quantity_obj;
                quantity_count++;
            });
            this.reservation_data[cart_id] = final_obj;
        });
    }

    validateSubmitAddress() {
        this.modalService.dismissAll();
        this.createReservationData();
        this.redirect_id = '';
        const validate_guestform = this.checkoutUtilsService.validate_guest(
            this.guests,
            true
        );
        if (!validate_guestform) {
            this.validateMipsCredit();
            this.moveToGuestNameDiv();
            return false;
        }
        const validate_age_flag = this.checkoutUtilsService.validate_child_age(
            this.guests
        );
        if (validate_age_flag == -1) {
            this.openValidationModal('Age is not in Specified range');
            return !1;
        } else if (validate_age_flag == 2) {
            this.openValidationModal('Age cannot be empty');
            setTimeout(() => {
                let div = document.querySelector('.age_box_empty');
                let divOffset = this.offset(div);
                let top = divOffset.top - 100;
                $('ion-pane.home-content.menu-content.pane').scrollTop(top);
            }, 100);
            return !1;
        }
        if (this.totalPrice < 0) {
            this.openValidationModal('Total price can not be less than zero.');
        } else if (parseInt(this.totalPrice) == 0) {
            this.checkout.slctdPymntMthd = 'free';
        }

        if (
            this.checkout.slctdPymntMthd == '' ||
            this.checkout.slctdPymntMthd == 0 ||
            this.checkout.slctdPymntMthd == undefined
        ) {
            this.openValidationModal('Please select payment method');
            this.togglePaymentMethod(undefined);
            return !1;
        } else if (
            this.payment_method_with_button_enabled != '1' &&
            !this.checkout.termsAndConditions
        ) {
            this.validateMipsCredit();
            this.openValidationModal('Please agree with Terms and Conditions');
            $('html, body').animate(
                { scrollTop: $(document).height() },
                'slow'
            );
            return !1;
        } else if (
            this.payment_method_with_button_enabled != '1' &&
            !this.checkout.iAmMauritian
        ) {
            this.validateMipsCredit();
            this.openValidationModal(
                'Please confirm to Mauritian Citizen or Resident'
            );
            return !1;
        }
        let userData = getUser();
        if (!userData) {
            this.login();
            return !1;
        }

        const billaddress = this.checkoutUtilsService.buildBillingAddress(
            this.billingAddress,
            this.selectedCountry.country_id
        );

        if (
            this.payment_method_with_button_enabled != '1' ||
            this.payment_method_with_button_enabled != 1
        ) {
            this.commonService.loadingText = `We\'re processing your order.`;
        }
        this.commonService.startProgress();

        this.checkService.updateProfile(billaddress, this.email).subscribe({
            next: (response: any) => {
                if (response.billaddress == 1) {
                    this.billAddress_status = 1;

                    this.MyAddress.billing.phone = response.updatedPhoneNumber;
                    this.MyAddress.shipping.phone = response.updatedPhoneNumber;
                    this.commonService.setCache(
                        this,
                        'billing_address',
                        JSON.stringify(billaddress)
                    );
                    if (
                        this.checkout.slctdPymntMthd !=
                        'mipspaymentsystemiframe'
                    ) {
                        this.callCheckoutInit();
                    }
                } else {
                    this.openValidationModal(response.errorMessage);
                }
            },
            error: () => {
                this.openValidationModal(
                    this.localeAlert.server_not_responding
                );
            },
        });
    }

    private openValidationModal(text: string) {
        this.eventService.emitEvent(
            EventType.MODAL_ERROR,
            this.translate.instant(text)
        );
    }

    getcountryCodeForMob() {
        let selectedCodes = document.querySelectorAll('.selected-dial-code');
        if (selectedCodes.length) {
            let codes = selectedCodes[0].innerHTML;
            if (selectedCodes.length > 1) {
                codes = selectedCodes[1].innerHTML;
            }
            return codes;
        }
    }

    offset(el: any) {
        let rect = el.getBoundingClientRect(),
            scrollLeft =
                window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop =
                window.pageYOffset || document.documentElement.scrollTop;
        console.log(
            'rect' +
                JSON.stringify(rect) +
                'scoll' +
                scrollLeft +
                'top' +
                scrollTop
        );
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    }

    switchPayment() {
        if (this.checkout.slctdPymntMthd == 'mipspaymentsystemiframe') {
            let output = this.paymentList.filter(
                (obj) => obj.payment_method_code == 'mipspaymentsystemiframe'
            );
            if (output.length > 0) {
                this.getPaymentMethod(output[0]);
            }
        }
    }

    togglePaymentMethod(group: any) {
        if (this.isPaymentMethodShown(group)) {
            this.shownPaymentMethod = undefined;
            this.checkout.selectedPaymentMethod = '';
            this.checkout.slctdPymntMthd = '';
            this.checkout.service_fee = 0;
            this.servicFeeVal = 0;
            this.payment_method_with_button_enabled = '';
            this.calculatePrice();
            this.calculateTotalViaServiceFee();
        } else {
            this.shownPaymentMethod = group;
        }
        this.calculateRPForReunionOrNot();
    }

    isPaymentMethodShown(group: any) {
        return this.shownPaymentMethod === group;
    }

    callCheckoutInit() {
        if (this.billAddress_status == 1) {
            this.checkoutInit();
        }
    }

    checkoutInit() {
        let userData = getUser();
        let currData;
        let itemData = [];

        if (!userData) {
            this.login();
            return false;
        }
        let logedIn: boolean = userData && userData.isSuccess;

        // Dummy navigate to invoice new page , order confirmation
        this.commonService.endProgress(); // need to remove later

        if (logedIn) {
            // home removed
            let productList = this.getProducts();
            let cachedData = this.commonService.getCache(this, 'cartItemsdata');
            if (cachedData.data.enable_checkout == false) {
                let msg = cachedData.data.cart_messages[0]['text'];
                this.openValidationModal(msg);
            } else {
                // //end place order data
                if (this.checkout.slctdPymntMthd) this.getPlaceOrderData();

                if (this.checkout.slctdPymntMthd == 'mipspaymentsystemiframe') {
                    this.place_order_url =
                        this.place_order_url +
                        '&mipsPaymentRefrence=' +
                        this.paymentRefrence;
                }
                this.commonService.loadingText = `We\'re processing your order.`;
                this.commonService.startProgress();
                let payment_orderid: any;

                this.checkService.serviceMethod(this.place_order_url).subscribe(
                    (response: any) => {
                        if (response.status == 1) {
                            if (this.checkout.slctdPymntMthd == 'ipay') {
                                let payment_data: any = {
                                    amount: this.totalPrice,
                                    siteurl: getBaseUrlMofluid(),
                                    orderid: response.orderid,
                                    source: 'webapp',
                                    origin:
                                        window.location.origin +
                                        window.location.pathname,
                                };
                                payment_data = this.commonService.encode(
                                    JSON.stringify(payment_data)
                                );
                                let payment_url =
                                    getBaseUrlMofluid() +
                                    'index.php/paymentipay/index?paymentdata=' +
                                    payment_data +
                                    '&islive=1';
                                this.commonService.startProgress();
                                this.checkService
                                    .serviceMethod(payment_url)
                                    .subscribe(
                                        (response: any) => {
                                            console.log(response);
                                            let redirectUrl =
                                                response.redirect_url;
                                            window.open(redirectUrl, '_self');
                                            this.checkout.slctdPymntMthd =
                                                'ipay';
                                            this.commonService.endProgress();
                                        },
                                        (err: any) => {
                                            console.log(err);
                                            this.commonService.endProgress();
                                            this.openValidationModal(
                                                this.localeAlert
                                                    .server_not_responding
                                            );
                                        }
                                    );
                            } else if (
                                this.checkout.slctdPymntMthd ==
                                'mipspaymentsystemiframe'
                            ) {
                                if (
                                    response.mips_status == '0' ||
                                    response.mips_status == 0
                                ) {
                                    this.commonService.endProgress();
                                    this.openValidationModal(
                                        response.mips_error
                                    );
                                } else {
                                    payment_orderid = response.orderid;
                                    let payment_OrderStatus =
                                        response.order_status;
                                    let payment_amount = this.totalPrice;
                                    this.commonService.startProgress();
                                    let billing = this.commonService.getCache(
                                        this,
                                        'billing_address'
                                    );
                                    this.processCreditCardPaymentInvoice(
                                        payment_orderid,
                                        billing,
                                        payment_amount,
                                        payment_OrderStatus,
                                        'mipspaymentsystemiframe'
                                    );
                                    this.router
                                        .navigate(['/checkoutpaymentSuccess'], {
                                            queryParams: {},
                                        })
                                        .then(() => {
                                            window.location.reload();
                                        });
                                }
                            } else {
                                this.router
                                    .navigate(['/invoicenew'], {
                                        queryParams: {},
                                    })
                                    .then(() => {});
                                this.processInvoice(
                                    response.orderid,
                                    this.MyAddress,
                                    this.totalPrice,
                                    this.checkout.slctdPymntMthd
                                );
                            }

                            productList.forEach((value: any, key: any) => {
                                itemData.push({
                                    item_id: value.sku,
                                    item_name: value.productName,
                                    item_category: value.product_category,
                                    price: value.price,
                                    quantity: value.quantity,
                                });
                            });
                            if (this.commonService.getCache(this, 'is_reunion'))
                                currData = 'EUR';
                            else currData = 'MUR';
                            gtag('event', 'purchase', {
                                currency: currData,
                                transaction_id: response.orderid, // Transaction ID.
                                value: this.totalPrice,
                                affiliation: 'Marideal',
                                shipping: 0,
                                tax: 0,
                                items: itemData,
                            });

                            gtag('event', 'Purchase Complete', {
                                event_category: 'Purchase Complete',
                                event_action: 'Order_Id= ' + payment_orderid,
                                event_label: 'Place Order',
                                event_value: '1',
                            });
                        } else {
                            if (response.type) {
                                this.openValidationModal(response.type);
                            } else {
                                this.openValidationModal(response.message);
                            }
                        }
                        this.commonService.endProgress();
                        this.appService.refreshPointsAndBalance();
                    },
                    (err: any) => {
                        console.log(err);
                        this.commonService.endProgress();
                        this.openValidationModal(
                            this.localeAlert.server_not_responding
                        );
                    }
                );
            }
        }
    }

    getProducts() {
        let products: any = new Array();
        console.log('this.items', this.items);
        for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].is_giftcard == undefined) {
                let current: any = new Object();
                current.id = this.items[i].id;
                current.sku = this.items[i].sku;
                current.quantity = this.items[i].qty;
                current.type = this.items[i].type;
                current.price = this.items[i].price;
                this.items[i].guest = [];
                current.guests = this.guests[i];
                current.productName = this.items[i].name;
                current.product_category = this.items[i].product_category;
                current.validityDate = this.items[i].validityDate;
                this.commonService.getCache(this, 'ms');
                let occupanyArray: any = new Array();
                let currentProductOption = {};
                let specialcurrentProductOption = [];
                let optionId = '';
                let inputType = '';
                if (
                    this.items[i].customOptions != '' &&
                    this.items[i].customOptions != null
                ) {
                    this.items[i].forEach((value: any, key: any) => {
                        if (
                            this.items[i].customOptions.type ==
                                'drop_down&timeslot' &&
                            value != 'drop_down&timeslot'
                        ) {
                            current.checkin = value.check_in
                                ? value.check_in
                                : '-';
                            current.checkout = value.check_out
                                ? value.check_out
                                : '-';
                            if (
                                current.checkin != '-' &&
                                current.checkout == '-'
                            )
                                current.checkout = current.checkin;
                            current.days = value.nights ? value.nights : '-';
                            inputType = 'drop_down&timeslot';
                            if (value.type == 'drop_down') {
                                let currentProductOptionval = [];
                                value.value.forEach(
                                    (values: any, keys: any) => {
                                        let temp: any = {};
                                        temp = {
                                            title: values.title,
                                            pax: values.selected,
                                            agegroup:
                                                '(' + values.agegroup + ')',
                                        };
                                        console.log('Temp', temp);
                                        if (!temp.equals({})) {
                                            currentProductOptionval.push(temp);
                                        }
                                    }
                                );
                                specialcurrentProductOption.push({
                                    option_id: value.option_id,
                                    option_label: value.title,
                                    is_qty: this.items[i].quantity,
                                    option_occupancy: currentProductOptionval,
                                    isAgencyType: 0,
                                });
                            } else if (value.type == 'timeslot') {
                                current.timeslot = value.optTitle;
                            }
                        } else if (value != 'drop_down&timeslot') {
                            current.checkin = value.check_in
                                ? value.check_in
                                : '-';
                            current.checkout = value.check_out
                                ? value.check_out
                                : '-';
                            if (
                                current.checkin != '-' &&
                                current.checkout == '-'
                            )
                                current.checkout = current.checkin;
                            current.days = value.total_nts
                                ? value.total_nts
                                : '-';
                            let occupancyObj: any = new Object();
                            occupancyObj.id = value.value;
                            occupancyObj.title = value.title;
                            occupancyObj.description = value.desc;
                            occupanyArray.push(occupancyObj);
                            if (
                                value.type == 'multiple' ||
                                value.type == 'checkbox'
                            ) {
                                let currentProductOptionval = [];
                                value.option_id.forEach(
                                    (values: any, keys: any) => {
                                        currentProductOptionval.push(values);
                                    }
                                );
                                currentProductOption[key] =
                                    currentProductOptionval;
                            } else {
                                if (value.value != null) {
                                    currentProductOption[key] = value.value;
                                    optionId = value.value;
                                }
                            }
                        }
                    });
                }
                if (this.items[i].agency) {
                    let optionoccupancyagency = {
                        title: this.items[i].agency,
                        pax: 0,
                        agegroup: '',
                    };
                    let optionoccupancyagencyarray = [];
                    optionoccupancyagencyarray.push(optionoccupancyagency);
                    specialcurrentProductOption.push({
                        option_id: '',
                        option_label: 'Agency',
                        is_qty: 1,
                        option_occupancy: optionoccupancyagencyarray,
                        isAgencyType: 1,
                    });
                }
                if (inputType == 'drop_down&timeslot') {
                    current.option_data = specialcurrentProductOption;
                    current.occupancy = '';
                    current.options = '';
                    current.room_id = this.items[i].timeslot_id || optionId;
                    current.timeslot = this.items[i].timeslot_name;
                    current.room_type = this.items[i].room_type;
                } else {
                    current.occupancy = this.items[i].quantity;
                    current.occupancy = occupanyArray;
                    current.options = currentProductOption;
                    current.room_id = optionId;
                    current.room_type = this.items[i].room_type;
                }
                if (this.items[i].bookedticketdata) {
                    current.seatedEventBookingData =
                        this.items[i].bookedticketdata.hiddenBookingData;
                    current.seatedEventBookingSeats =
                        this.items[i].bookedticketdata.hiddenseat;
                    current.isSeatedEvent = true;
                    current.mofluid_quote_id = this.commonService.getCache(
                        this,
                        'ticketmofluid'
                    )
                        ? this.commonService.getCache(this, 'ticketmofluid')
                              .data
                        : '-1';
                } else {
                    current.seatedEventBookingData = '';
                    current.seatedEventBookingSeats = [];
                    current.isSeatedEvent = false;
                }
                products.push(current);
            }
        }
        return products;
    }

    getPlaceOrderData() {
        let gift_object = this.getGiftObject();
        let user_address = this.commonService.encode(
            JSON.stringify(this.MyAddress)
        );
        console.log('giftObj Stringyfied', JSON.stringify(gift_object));
        let reservation_data = this.commonService.encode(
            JSON.stringify(this.reservation_data)
        );
        let quoteid = localStorage.getItem('quoteid');
        let platform = this.commonService.getCache(this, 'platform')
            ? this.commonService.getCache(this, 'platform').data
                ? this.commonService.getCache(this, 'platform').data
                : ''
            : '';
        let androidOs = this.commonService.getCache(this, 'androidOs')
            ? this.commonService.getCache(this, 'androidOs').data
                ? this.commonService.getCache(this, 'androidOs').data
                : ''
            : '';
        let appCurrency = appConfig.app_currency;
        if (
            this.commonService.getCustomerGroupID() == 8 ||
            this.commonService.getCustomerGroupID() == 10
        ) {
            appCurrency = 'EUR';
        }

        if (platform == 'android') {
            platform = 'Android';
        }
        if (platform == null || platform == '')
            this.place_order_url =
                this.commonService.api_url +
                'placeorder?service=placeorder&customerid=' +
                this.customerId +
                '&address=' +
                user_address +
                '&mofluid_discount=' +
                this.coupon_code_discount +
                '&currency=' +
                appCurrency +
                '&paymentmethod=' +
                this.checkout.slctdPymntMthd +
                '&shipmethod=' +
                '' +
                '&products=' +
                '' +
                '&is_create_quote=1&giftcodes=' +
                btoa(JSON.stringify(gift_object)) +
                '&spending_points=' +
                parseInt(this.usedRwPt) +
                '&store_credits=' +
                this.store_discount_amount +
                '&plateform=WebPWA&cardDetails=' +
                btoa(JSON.stringify(this.sendCardInfo)) +
                '&checkoutId=' +
                this.customerCardInfo.id +
                '&giftCardProducts=' +
                '' +
                '&gift_card_credit=' +
                this.gift_value_used +
                '&reservation_data=' +
                reservation_data +
                '&quoteid=' +
                quoteid +
                '&mips_mobile_payment=1&new_plateform_device=1&store=' +
                this.commonService.getStoreID() +
                '&customer_group_id=' +
                this.commonService.getCustomerGroupID() +
                '&service_fee=' +
                this.servicFeeVal;
        else {
            this.place_order_url =
                this.commonService.api_url +
                'placeorder?service=placeorder&customerid=' +
                this.customerId +
                '&address=' +
                user_address +
                '&mofluid_discount=' +
                this.coupon_code_discount +
                '&currency=' +
                appCurrency +
                '&paymentmethod=' +
                this.checkout.slctdPymntMthd +
                '&shipmethod=' +
                '' +
                '&products=' +
                '' +
                '&is_create_quote=1&giftcodes=' +
                btoa(JSON.stringify(gift_object)) +
                '&spending_points=' +
                parseInt(this.usedRwPt) +
                '&store_credits=' +
                this.store_discount_amount +
                '&plateform=WebPWA&cardDetails=' +
                btoa(JSON.stringify(this.sendCardInfo)) +
                '&checkoutId=' +
                this.customerCardInfo.id +
                '&giftCardProducts=' +
                '' +
                '&gift_card_credit=' +
                this.gift_value_used +
                '&reservation_data=' +
                reservation_data +
                '&quoteid=' +
                quoteid +
                '&mips_mobile_payment=1&new_plateform_device=1&plateform=' +
                platform +
                ' ' +
                androidOs +
                '&store=' +
                this.commonService.getStoreID() +
                '&customer_group_id=' +
                this.commonService.getCustomerGroupID() +
                '&service_fee=' +
                this.servicFeeVal;
        }
    }

    processInvoice(orderid: any, address: any, amount: any, paymethod: any) {
        let payment_m = '';
        if (paymethod == 'cashondelivery') {
            payment_m = 'COD';
        } else if (paymethod == 'banktransfer') {
            payment_m = 'Bank Transfer';
        } else {
            payment_m = paymethod;
        }
        let state = '';
        if (address.billing.state.region_name) {
            state = address.billing.state.region_name;
        } else {
            state = address.billing.stateCustom;
        }
        let Order: any = new Object();
        Order.payment_method = payment_m;
        Order.orderid = orderid;
        Order.name = address.billing.firstname + ' ' + address.billing.lastname;
        Order.city = address.shipping.city;
        Order.state = state;
        Order.address = address.shipping.address;
        Order.amount = amount;
        Order.email = address.billing.email;
        Order.contactno = address.shipping.phone;
        Order.pincode = address.shipping.zipcode;
        Order.transid = 111;
        Order.ship_type = 1;
        Order.recemail = this.email;
        let JOrder = JSON.stringify(Order);
        this.commonService.setCache(this, 'orderdata', JOrder);
    }

    processCreditCardPaymentInvoice(
        orderid: any,
        address: any,
        amount: any,
        orderStatus: any,
        payment_m: any
    ) {
        let addressData = JSON.parse(address.data);
        let userData = getUser();
        let Order: any = new Object();
        Order.payment_method = payment_m;
        Order.orderStatus = orderStatus;
        Order.orderid = orderid;
        Order.name = addressData.billfname + ' ' + addressData.billlname;
        Order.city = addressData.billcity;
        Order.state = addressData.billstate;
        Order.address = addressData.billstreet1 + ' ' + addressData.billstreet2;
        Order.amount = amount;
        Order.email = userData.username;
        Order.contactno = addressData.billphone;
        Order.pincode = addressData.billpostcode;
        Order.transid = 111;
        Order.ship_type = 1;
        Order.recemail = userData.username;
        let JOrder = JSON.stringify(Order);
        this.commonService.setCache(this, 'orderdata', JOrder);
    }

    getGiftObject() {
        let giftObj: any = [];
        this.applyiedGiftCode.forEach((value: any, key: any) => {
            let gift_object: any = {
                gift_code: value.gift_code,
                balance: Number(value.balance),
                update: 1,
                update_amount: value.applyiedBalance,
            };
            giftObj.push(gift_object);
        });
        return giftObj;
    }

    reloadMipsPaymentCreditCardView() {
        this.mipsIframeLoading = true;
        $('#mipsIframe').attr('src', '');
        if (window.location.href.includes('FR')) {
            this.language = 'FR';
        } else if (window.location.href.includes('EN')) {
            this.language = 'EN';
        }

        $('#mipsIframe').css({
            background: 'url("img/mips_loader.svg") no-repeat center',
        });
        let userData = getUser();
        if (!userData) {
            this.login();
        } else if (userData) {
            let payment_data: any;
            this.paymentRefrence =
                Math.floor(1000 + Math.random() * 9000) + '' + this.customerId;

            let is_reunion = this.commonService.getCache(this, 'is_reunion')
                ? this.commonService.getCache(this, 'is_reunion').data
                : false;

            if (!is_reunion) {
                this.totalPrice = Math.round(this.totalPrice);
            }

            if (this.payment_method_with_button_enabled == '1') {
                let quoteid = localStorage.getItem('quoteid');
                this.checkoutUtilsService.validate_child_age(this.guests);
                this.createReservationData();
                this.getPlaceOrderData();
                let place_order = this.commonService.encode(
                    this.place_order_url
                );
                payment_data = {
                    amount: this.totalPrice.toFixed(2),
                    servicefee: this.servicFeeVal,
                    mofluid_discount: this.coupon_code_discount,
                    siteurl: getBaseUrlMofluid(),
                    orderid: quoteid,
                    source: 'webapp',
                    origin: window.location.origin + window.location.pathname,
                    placeorder_url: place_order,
                    iframe_language: this.language,
                };
            } else {
                payment_data = {
                    amount: this.totalPrice.toFixed(2),
                    servicefee: this.servicFeeVal,
                    mofluid_discount: this.coupon_code_discount,
                    siteurl: getBaseUrlMofluid(),
                    orderid: this.paymentRefrence,
                    source: 'webapp',
                    origin: window.location.origin + window.location.pathname,
                    iframe_language: this.language,
                };
            }
            console.log('pymnt data' + JSON.stringify(payment_data));
            payment_data = this.commonService.encode(
                JSON.stringify(payment_data)
            );
            let payment_url =
                getBaseUrlMofluid() +
                'paymentmips/index?paymentdata=' +
                payment_data +
                '&mips_mobile_payment=1';
            this.checkService.serviceMethod(payment_url).subscribe(
                (response: any) => {
                    console.log(response);
                    this.redirectedUrl = response.redirect_url;
                    $('#mipsIframe').attr('src', this.redirectedUrl);
                    setTimeout(() => {
                        $('#exp_carte_annee').css('width', '85px');
                    }, 1000);
                    this.mipsIframeLoading = false;
                },
                () => {
                    this.openValidationModal(
                        this.localeAlert.server_not_responding
                    );
                }
            );
        }
    }
    removeServiceFee() {
        if (
            this.subtotalPrice <=
                this.giftcard + Number(this.store_discount_amount) &&
            this.isGift &&
            this.isStorePoint &&
            this.isAddGiftClicked
        ) {
            this.giftcard =
                this.giftcard - parseInt(this.servicFeeVal.toFixed(0));
            if (this.totalPrice - parseInt(this.servicFeeVal.toFixed(0)) > 0) {
                this.totalPrice =
                    this.totalPrice - parseInt(this.servicFeeVal.toFixed(0));
            }
            this.servicFeeVal = 0;
        } else {
            if (
                this.subtotalPrice <= this.giftcard &&
                this.isGift &&
                this.isAddGiftClicked
            ) {
                this.servicFeeVal = 0;
                console.log(this.servicFeeVal, 'servicefee');
            }
            if (
                this.subtotalPrice <= this.store_discount_amount &&
                this.isStorePoint
            ) {
                this.servicFeeVal = 0;
            }
        }
    }
    isAddGiftClicked: boolean = false;

    addGift() {
        this.isAddGiftClicked = true;
        if (!this.checkout.giftValue && !this.checkout.selectedGiftCode) {
            this.openValidationModal('Please enter or chose your code');
            return;
        }
        this.commonService.startProgress();
        let giftCode = this.checkout.giftValue;
        if (!giftCode) {
            giftCode = this.checkout.selectedGiftCode.gift_code;
        }

        let profileUrl =
            this.commonService.api_url +
            'giftCardValidation?service=giftCardValidation&giftcode=' +
            this.commonService.encode(giftCode) +
            '&customerid=' +
            this.customerId +
            '&customer_group_id=' +
            this.commonService.getCustomerGroupID();
        this.checkService.serviceMethod(profileUrl).subscribe(
            (response: any) => {
                if (response.status == 0) {
                    this.openValidationModal(response.error);
                } else {
                    this.giftCardAppliedCount += 1;
                    this.isGiftCardApplied =
                        this.giftCardAppliedCount >= 20 ? false : true;
                    this.giftcard = Number(response.giftcard_details.balance);
                    this.max_gift_discount = this.giftcard;
                    if (
                        this.store_discount_amount >= 0 &&
                        this.giftcard > this.totalPrice
                    ) {
                        if (
                            this.totalPrice +
                                Number(this.store_discount_amount) <
                            this.max_gift_discount
                        ) {
                            this.giftcard =
                                this.giftcard -
                                Math.abs(this.totalPrice - this.giftcard);
                            this.max_gift_discount = Math.abs(
                                this.totalPrice - this.max_gift_discount
                            );
                            this.totalPrice = Number(
                                this.totalPrice - this.max_gift_discount
                            );
                        } else if (
                            this.totalPrice +
                                Number(this.store_discount_amount) >=
                            this.max_gift_discount
                        ) {
                            this.giftcard =
                                this.giftcard -
                                Math.abs(this.totalPrice - this.giftcard);
                            this.max_gift_discount = Math.abs(
                                this.totalPrice - this.max_gift_discount
                            );
                            this.totalPrice = Number(
                                this.totalPrice - this.max_gift_discount
                            );
                        } else {
                            this.giftcard = this.max_gift_discount;
                            if (this.totalPrice > 0) {
                                if (this.totalPrice > this.giftcard) {
                                    this.totalPrice -= this.giftcard;
                                } else {
                                    this.store_discount_amount =
                                        this.store_discount_amount -
                                        (this.giftcard - this.totalPrice);
                                    this.totalPrice = 0;
                                }
                            } else {
                                this.store_discount_amount -= this.giftcard;
                            }
                        }
                    } else {
                        this.giftcard =
                            this.giftcard > this.totalPrice
                                ? (this.giftcard = this.totalPrice)
                                : this.giftcard;
                    }
                    this.checkout.giftValue = '';
                    this.checkout.selectedGiftCode = '';
                    let addedGiftCodeDetails = response.giftcard_details;
                    addedGiftCodeDetails.applyiedBalance = Number(
                        this.giftcard
                    );
                    this.applyiedGiftCode.push(addedGiftCodeDetails);
                    this.existed_giftCodes = this.user_giftCodes.filter(
                        (ar) =>
                            !this.applyiedGiftCode.find(
                                (rm) => rm.gift_code === ar.gift_code
                            )
                    );
                    this.gift_discount = this.applyiedGiftCode.reduce(
                        (sum: any, current: any) => {
                            return sum + Number(current.applyiedBalance);
                        },
                        0
                    );
                    this.data1 = {
                        gift_input_val: Number(this.gift_discount),
                    };
                    this.calculateTotal();
                }
                this.applyRewardPoints();
            },
            () => {
                this.openValidationModal(
                    this.localeAlert.server_not_responding
                );
            }
        );
        this.calculateTotal();
    }

    edit_gift_amount(giftCode: any) {
        this.data1.edittedGiftValue = giftCode.applyiedBalance;
    }

    remove_All_GiftCode() {
        if (
            this.totalPrice < this.credit_amount &&
            this.subtotalPrice > this.credit_amount &&
            this.isStorePoint
        ) {
            this.store_discount_amount = this.credit_amount;
            this.totalPrice = this.totalPrice - this.store_discount_amount;
        } else if (
            this.totalPrice < this.credit_amount &&
            this.subtotalPrice <= this.credit_amount &&
            this.isStorePoint
        ) {
            this.store_discount_amount = this.subtotalPrice;
            this.totalPrice = this.totalPrice - this.store_discount_amount;
        }
        this.applyiedGiftCode = [];
        this.existed_giftCodes = this.user_giftCodes.filter(
            (ar) =>
                !this.applyiedGiftCode.find(
                    (rm) => rm.gift_code === ar.gift_code
                )
        );
        this.gift_discount = this.applyiedGiftCode.reduce(
            (sum: any, current: any) => {
                return sum + Number(current.applyiedBalance);
            },
            0
        );
        this.data1 = {
            gift_input_val: Number(this.gift_discount),
        };
        this.calculateTotal();
    }

    remove_gift(giftCode) {
        this.applyiedGiftCode = this.applyiedGiftCode.filter(
            (x) => x.gift_code !== giftCode.gift_code
        );
        this.existed_giftCodes = this.user_giftCodes.filter(
            (ar) =>
                !this.applyiedGiftCode.find(
                    (rm) => rm.gift_code === ar.gift_code
                )
        );
        this.gift_discount = this.applyiedGiftCode.reduce(
            (sum: any, current: any) => {
                return sum + Number(current.applyiedBalance);
            },
            0
        );
        this.data1 = {
            gift_input_val: Number(this.gift_discount),
        };
        $('#enter_giftcard').val('');
        this.isGiftCardApplied = true;
        this.giftCardAppliedCount =
            this.giftCardAppliedCount > 1 ? this.giftCardAppliedCount - 1 : 0;
        this.calculateTotal();
    }

    checkconditiononcheckout() {
        $('input').blur();
    }

    uncheck_gift(isGift) {
        if (!isGift) {
            this.isGift = false;
        }
        $('input').blur();
        $('#gift_code').css({ display: 'block' });
        if (!$('input[type=checkbox][id=StoreCredit]').prop('checked')) {
            this.remove_All_GiftCode();
            this.isGiftCardApplied = true;
            this.giftCardAppliedCount = 0;
            return !1;
        }
        this.calculateTotal();
    }

    toggleApplyDiscountCode() {
        this.discountCodeShow = !this.discountCodeShow;

        // if (this.isDiscountCodeApplied && this.discountCodeShow) {
        //     this.deleteDiscountCode();
        // }
        // this.calculateTotal();
    }

    deleteDiscountCode() {
        let deleteCouponUrl =
            this.commonService.api_url +
            'deleteCartCoupon?service=deleteCartCoupon' +
            '&customerid=' +
            this.customerId +
            '&customer_group_id=' +
            this.commonService.getCustomerGroupID();
        this.commonService.startProgress();
        this.checkService.serviceMethod(deleteCouponUrl).subscribe(
            (response: any) => {
                if (response.status == 0 || response.status == 'error') {
                    this.openValidationModal(response.message);
                } else if (response.status == 'success') {
                    this.isDiscountCodeApplied = false;
                    this.updateCostsBasedOnResponse(
                        undefined,
                        response,
                        false,
                        true
                    );
                }
            },
            (err: any) => {
                this.openValidationModal(
                    this.localeAlert.server_not_responding
                );
            }
        );
    }

    AddDiscountCode(values: any) {
        let applyCouponUrl =
            this.commonService.api_url +
            'applyCartCoupon?service=applyCartCoupon' +
            '&customerid=' +
            this.customerId +
            '&coupon_code=' +
            values.trim() +
            '&customer_group_id=' +
            this.commonService.getCustomerGroupID();
        this.commonService.startProgress();
        this.checkService.serviceMethod(applyCouponUrl).subscribe(
            (response: any) => {
                if (response.status == 0 || response.status == 'error') {
                    this.openValidationModal(response.message);
                } else if (response.status == 'success') {
                    this.updateCostsBasedOnResponse(undefined, response, false);
                }
            },
            () => {
                this.openValidationModal(
                    this.localeAlert.server_not_responding
                );
            }
        );
    }

    updateCostsBasedOnResponse(
        items: any[] | undefined,
        response: any,
        clearGuests: boolean,
        discountCodeApplied: boolean = true
    ) {
        if (items) {
            this.items = items;
        }
        this.cart_total_amount = parseFloat(response.cart_total_amount);
        this.coupon_code_discount = parseFloat(response.coupon_code_discount);
        this.cart_order_total = response.cart_order_total;
        this.discountCode = response.coupon_code;
        this.discountCodeTitle = response.coupon_code_title;
        this.isDiscountCodeApplied = discountCodeApplied;
        this.discountCodeShow = !0;
        if (clearGuests) {
            this.guests = [];
        }
        this.getCustomerAddress();
        this.calculatePrice();
        this.get_gift_card_credit();
        this.calculateRewardPoint();
        setTimeout(() => {
            this.getAvlbPaymentMthds();
        }, 1000);
    }

    applyDiscountCode(values: any) {
        this.gift_card_credit_check_box = !1;
        // set the points spend to 0
        // if (
        //     $('input[type=checkbox][value="Spend Your Points"]').prop('checked')
        // ) {
        this.rw_discountAmount = 0;
        this.usedRwPt = 0;
        //     $('.chk-rewards-item-syp-input').val(0);
        // }
        // set the gift card to 0
        // $('input[type=checkbox][value="Gift Card Credit"]').prop(
        //     'checked',
        //     false
        // );
        // set the gift cards to 0
        // $('input[type=checkbox][value="Gift Card"]').prop('checked', false);
        this.gift_value_used = 0;
        this.store_discount_amount = 0;
        // $('input[type=checkbox][value="StoreCredit"]').prop('checked', 0);
        // $('.hidden_gift').css({ display: 'none' });
        // $('#gift_code').css({ display: 'none' });
        // $('#enter_giftcard').val('');
        this.calculateTotal();
        // $('#reward_point_outer').css({ display: 'none' });
        if (this.isDiscountCodeApplied) {
            this.deleteDiscountCode();
            // $('input[type=checkbox][value="Spend Your Points"]').prop(
            //     'checked',
            //     false
            // );
            return;
        }
        if (!values) {
            this.openValidationModal(this.localeAlert.plz_enter_discount_code);
            return;
        }
        this.AddDiscountCode(values);
        this.applyRewardPoints();
    }

    store_credit() {
        let userData = getUser();
        if (userData) {
            let storeCreditUrl =
                this.commonService.api_url +
                'findStoreCredit?service=findStoreCredit&customerid=' +
                this.customerId +
                '&customer_group_id=' +
                this.commonService.getCustomerGroupID();
            this.checkService.serviceMethod(storeCreditUrl).subscribe(
                (response: any) => {
                    this.storeLabel = response;
                    this.credit_amount =
                        response.storecredit_details.credit_amount;
                },
                (err: any) => {}
            );
        }
    }

    uncheckGiftAndApplyStoreCredit() {
        this.payment_method_with_button_enabled = '';
        this.uncheck_gift(false);
        this.apply_store_credit(false);
    }

    apply_store_credit(isStorePoint) {
        if (!isStorePoint) {
            this.isStorePoint = false;
            this.remove_store();
        } else {
            this.discount_validation('store');
        }
        this.calculateTotal();
        this.applyRewardPoints();
    }

    remove_store() {
        this.totalPrice =
            Number(this.totalPrice) + Number(this.store_discount_amount);
        let gift_object = this.getGiftObject();

        if (gift_object.length) {
            this.giftcard = gift_object[0]?.balance;
            if (this.subtotalPrice < this.giftcard) {
                this.gift_discount =
                    this.giftcard - (this.giftcard - this.subtotalPrice);
            } else {
                this.gift_discount = this.giftcard;
            }
        }
        if (this.isGift) {
            this.applyiedGiftCode[0].applyiedBalance = Number(
                this.gift_discount
            );
        }
        this.store_discount_amount = 0;
        this.calculateTotal();
        gift_object = this.getGiftObject();
    }

    getUrllets() {
        let lets: any = {};
        let fn: any = (m: any, key: any, value: any) => {
            lets[key] = value;
        };
        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, fn);
        return lets;
    }

    // TODO: may need to remove
    addRewardPoint(step: any, maxPt: any) {
        if (this.order_count >= 1) {
            let currRwPoints = parseInt($('.chk-rewards-item-syp-input').val());
            if (currRwPoints < maxPt) {
                let new_rw_val = parseInt(currRwPoints + step);
                $('.chk-rewards-item-syp-input').val(new_rw_val);
                this.usedRwPt = new_rw_val;
                this.applyRewardPoints();
            }
        }
    }

    getExistingGiftCodeTitle(giftCode: any) {
        return (
            giftCode.hidden_code +
            ' (' +
            giftCode.balance_format.slice(0, -3) +
            ')'
        );
    }

    updateRwPoint(value: string) {
        if (this.order_count >= 1) {
            this.usedRwPt = parseInt(value);
            this.checkMaxPoints();
            this.applyRewardPoints();
            let usedRwPt = this.usedRwPt;
            let rw_slider_max = this.rw_slider.slider_max;
            let rw_slider_width = (usedRwPt / rw_slider_max) * 100;
            document.documentElement.style.setProperty(
                '--slider-value',
                `${rw_slider_width}%`
            );
        }
    }

    private checkMaxPoints() {
        if (this.usedRwPt > this.rw_slider.slider_max) {
            this.usedRwPt = this.rw_slider.slider_max;
        }
    }

    submitAddress() {
        let quoteid = !localStorage.getItem('quoteid')
            ? null
            : localStorage.getItem('quoteid');

        let getCartItem =
            this.commonService.api_url +
            'getAllCartItems?store=' +
            this.commonService.getStoreID() +
            '&currency=MUR&service=getAllCartItems&customerid=' +
            this.customerId +
            '&quoteid=' +
            quoteid +
            '&customer_group_id=' +
            this.commonService.getCustomerGroupID();
        this.checkService.serviceMethod(getCartItem).subscribe(
            (response: any) => {
                this.showCartSkeleton = false;
                if (response.status == 'success') {
                    this.conversion_rate = Number(response.conversion_rate);
                    this.reward_point_rate = Number(response.reward_point_rate);
                    this.order_count = parseInt(response.order_count);
                    this.showRewardPointErrorEle =
                        this.order_count < 1 ? true : false;
                    this.cart_total_amount = parseFloat(
                        response.cart_total_amount
                    );
                    this.coupon_code_discount = parseFloat(
                        response.coupon_code_discount
                    );
                    this.cart_order_total = response.cart_order_total;
                    this.discountCodeTitle = response.coupon_code_title;
                    this.allotment_check_mdpErrorMsg =
                        response.allotment_check_mdpErrorMsg;

                    if (
                        this.allotment_check_mdpErrorMsg &&
                        this.allotment_check_mdpErrorMsg.length > 0
                    ) {
                        this.openValidationModal(
                            response.allotment_check_mdpErrorMsg
                        );
                    } else {
                        this.validateSubmitAddress();
                        this.callCheckoutInit();
                    }
                } else {
                    this.openValidationModal(response.message);
                }
            },
            () => {
                this.openValidationModal(
                    this.localeAlert.server_not_responding
                );
            }
        );
    }

    login(): void {
        this.eventService.emitEvent(EventType.LOGIN, '/checkout');
    }

    round(value: number): number {
        return Math.round(value);
    }

    getImageSourceBasedOnCode(code: string): string {
        switch (code) {
            case 'mipspaymentsystemiframe':
                return 'assets/icons/checkout/payment/juice_blink.svg';
            case 'banktransfer':
                return 'assets/icons/checkout/payment/credit_card.svg';
            default:
                return 'assets/icons/checkout/payment/pay_later.svg';
        }
    }

    openModal(content: ModalOffcanvasComponent) {
        content.open();
        setTimeout(() => {
            this.reloadMipsPaymentCreditCardView();
        }, 100);
    }

    goToGiftCardManagement() {
        this.router.navigate(['/addgift-voucher']);
    }
}
