import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    BehaviorSubject,
    combineLatest,
    Observable,
    switchMap,
    tap,
} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular-ivy';
import * as dayjs from 'dayjs';
import {
    DealDto,
    AgeGroup,
    AccomOffer,
    AccomOptionDto,
    AccomInventory,
    InventoryDto,
} from 'src/app/model/deal.model';
import { HotelBookingService } from './hotel-booking.service';
import { transformInventoryDto } from './hotel-booking.model';
import { CurrencyLanguageService } from 'src/app/shared/currency-language.service';
import {
    CalendarComponent,
    CalendarDayInfo,
    CalendarSelectedDates,
} from 'src/app/controls/calendar/calendar.component';
import {
    BookingConfiguredData,
    HotelSearchBookingData,
    emptyBookingConfiguredData,
} from '../booking-config.model';
import { ProductDetailTransformer } from '../../model/product-detail.transformers';
import { transformNgbDateToDayJs } from 'src/app/controls/calendar/calendar.utils';
import { TRAVEL_CATEGORY } from 'src/app/static-content/menu-routes';
import { ProductService } from '../../product.service';
import { OccupancyComponent } from 'src/app/controls/occupancy/occupancy.component';
import { getOccupancyLabelFromAgeGroups } from '@app/controls/occupancy/occupancy.utils';

@UntilDestroy()
@Component({
    selector: 'md-hotel-booking',
    templateUrl: './hotel-booking.component.html',
    styleUrls: ['./hotel-booking.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HotelBookingComponent implements OnInit, AfterViewInit {
    private productDetailsSubject = new BehaviorSubject<DealDto>(null);

    private productDetails$ = this.productDetailsSubject.asObservable().pipe(
        tap((value) => {
            if (!value) return;
            this.productDetails = value;

            this.isTravel = this.productDetails.categoryId === TRAVEL_CATEGORY;

            this.visibleServiceCategoriesAndOptions =
                this.productDetails.accomOffers;

            this.selectedServiceOptionInternal =
                this.visibleServiceCategoriesAndOptions[0]?.accomOptions[0];

            this.addMaxPaxToAgeGroups();

            this.selectedOccupancyId = 1332;

            if (this.productDetails.inventory)
                this.setAccomInventory({ noOpenCalendar: true });
        })
    );

    @Input() set productDetailsInput(value: DealDto) {
        this.productDetailsSubject.next(value);
    }

    @Input() initialBookingData: HotelSearchBookingData;

    visibleServiceCategoriesAndOptions: AccomOffer[];
    currencySymbol: 'Rs.' | 'EUR';
    locale: 'en-MU' | 'fr-RE';
    pricingByDateForSelectedServiceOption: AccomInventory[];
    additionalCalendarDayInfo: Map<string, CalendarDayInfo> = new Map();
    selectedServiceOptionInternal: AccomOptionDto;
    selectedOccupancyIdInternal: number;
    bookingSelected = false;
    productDetails: DealDto;
    maxGuestInternal = 2;
    calendarErrorMessage = '';

    isTravel = false;
    isProcessingOptions = false;
    isMaxForSelectedOption = false;

    lastBookingData: BookingConfiguredData = emptyBookingConfiguredData;
    lastSelectedDates: CalendarSelectedDates;

    fromDate: dayjs.Dayjs;
    toDate: dayjs.Dayjs;
    totalNights = 0;

    private hasSetOccupancyFirstRan = false;

    @Output() bookingConfigured = new EventEmitter<BookingConfiguredData>();
    @ViewChild('calendarHotel') calendarHotel: CalendarComponent;
    @ViewChild('occupancy') occupancyComponent: OccupancyComponent;

    set selectedOccupancyId(selectedOccupancyId: number) {
        this.selectedOccupancyIdInternal = selectedOccupancyId;

        // NOOP on first run
        if (!this.hasSetOccupancyFirstRan) {
            this.hasSetOccupancyFirstRan = true;
            return;
        }

        if (!this.selectedServiceOptionInternal)
            this.selectedServiceOptionInternal =
                this.visibleServiceCategoriesAndOptions[0]?.accomOptions[0];

        let fetchAccomOptions$: Observable<{
            accomOptions: AccomOptionDto[];
            accomInventory: InventoryDto[];
        }>;

        // => No options
        if (!this.selectedServiceOptionInternal)
            fetchAccomOptions$ = this.productService.fetchAccomOptions(
                this.productDetails,
                undefined,
                selectedOccupancyId
            );
        else {
            const accomOptionKey = `${this.selectedServiceOptionInternal.mealPlanId}:${this.selectedServiceOptionInternal.roomId}`;

            fetchAccomOptions$ = this.productService.fetchAccomOptions(
                this.productDetails,
                undefined,
                selectedOccupancyId,
                accomOptionKey
            );
        }

        this.hasSetOccupancyFirstRan &&
            fetchAccomOptions$
                .pipe(
                    untilDestroyed(this),
                    tap(({ accomOptions, accomInventory }) => {
                        this.productDetails.accomOffers =
                            this.productDetailTransformer.transformAccomOptions(
                                accomOptions,
                                this.productDetails.ageGroups
                            );

                        this.productDetails.inventory =
                            this.productDetailTransformer.transformInventory(
                                this.productDetails,
                                [],
                                accomInventory
                            );

                        this.productDetails = { ...this.productDetails };

                        this.visibleServiceCategoriesAndOptions =
                            this.productDetails.accomOffers;

                        if (!this.selectedServiceOptionInternal)
                            this.selectedServiceOptionInternal =
                                this.visibleServiceCategoriesAndOptions[0]?.accomOptions[0];

                        const selectedOption = accomOptions.find(
                            (option) =>
                                this.selectedServiceOptionInternal
                                    .mealPlanId === option.mealPlanId &&
                                this.selectedServiceOptionInternal.roomId ===
                                    option.roomId
                        );

                        this.setSelectedServiceOption(selectedOption);

                        this.emitBookingOnPricingChange();
                    })
                )
                .subscribe();
    }

    setSelectedServiceOption(productServiceOption: AccomOptionDto): void {
        this.selectedServiceOptionInternal = productServiceOption;
        this.addMaxPaxToAgeGroups();
        this.calcOccupancyTotal();
        this.setAccomInventory();
    }

    constructor(
        private hotelBookingService: HotelBookingService,
        private currencyLanguageService: CurrencyLanguageService,
        private ref: ChangeDetectorRef,
        private translate: TranslateService,
        private productService: ProductService,
        private productDetailTransformer: ProductDetailTransformer
    ) {}

    ngOnInit(): void {
        const currency$ = this.currencyLanguageService
            .getCurrency()
            .pipe(tap((currency) => (this.currencySymbol = currency)));

        const productDetails$ = currency$.pipe(
            switchMap(() => this.productDetails$)
        );

        combineLatest([currency$, productDetails$])
            .pipe(untilDestroyed(this))
            .subscribe();

        this.currencyLanguageService
            .getLocaleForCurrency()
            .pipe(untilDestroyed(this))
            .subscribe((locale) => {
                this.locale = locale;
            });
    }

    ngAfterViewInit(): void {
        this.occupancyComponent.occupancyChanged
            .pipe(
                switchMap((ageGroups: AgeGroup[]) => {
                    console.log('occupancy');
                    this.productDetails.ageGroups = ageGroups;
                    this.calcOccupancyTotal();
                    return this.hotelBookingService.getOccupancyId(ageGroups);
                }),
                tap(({ id }) => (this.selectedOccupancyId = id))
            )
            .subscribe();
    }

    onServiceOptionChanged(accomOption: AccomOptionDto): void {
        if (this.isProcessingOptions) return;

        this.isProcessingOptions = true;

        const accomOptions = this.productDetails.accomOffers.flatMap(
            (accomOffer) => accomOffer.accomOptions
        );

        const accomOptionKey = `${accomOption.mealPlanId}:${accomOption.roomId}`;

        this.productService
            .fetchAccomInventory(
                this.productDetails,
                this.selectedOccupancyIdInternal,
                accomOptions,
                accomOptionKey
            )
            .pipe(
                untilDestroyed(this),
                tap(({ accomInventory }) => {
                    this.productDetails.inventory =
                        this.productDetailTransformer.transformInventory(
                            this.productDetails,
                            [],
                            accomInventory
                        );

                    this.productDetails = { ...this.productDetails };

                    this.setSelectedServiceOption(accomOption);

                    this.emitBookingOnPricingChange();

                    this.isProcessingOptions = false;
                })
            )
            .subscribe();
    }

    onDatesClick() {
        this.calendarHotel.openCalendar();
    }

    onDateRangeSelected(selectedDates: CalendarSelectedDates): void {
        const { fromDate, toDate, totalNights } = selectedDates;

        this.lastSelectedDates = selectedDates;

        if (!fromDate || !toDate) {
            this.emitBookingData(this.lastBookingData);
            this.setCheckinCheckoutUI(undefined, undefined, undefined);
            this.bookingSelected = false;
            return;
        }
        this.setCheckinCheckoutUI(fromDate, toDate, totalNights);
        this.bookingSelected = true;

        const { total, flightPrice } = this.getBookingTotal(selectedDates);

        this.emitBookingData({
            totalPrice: total.totalBooked,
            totalFullPrice: total.totalFull,
            flightPrice: flightPrice,
            productBookingData: {
                id: this.productDetails.dealId,
                selectedAccomOptions: [this.selectedServiceOptionInternal],
                checkinDate: fromDate,
                checkoutDate: toDate,
                totalNights,
                occupancy: {
                    occupancyId: this.selectedOccupancyIdInternal,
                    occupancyLabel: getOccupancyLabelFromAgeGroups(
                        this.productDetails.ageGroups,
                        this.translate
                    ),
                },
            },
        });
    }

    private getAllBookedDates(
        fromDate: NgbDate,
        toDate: NgbDate,
        totalNights: number
    ): AccomInventory[] {
        const allBookedDates =
            this.pricingByDateForSelectedServiceOption.filter(
                (pricingByDate) =>
                    fromDate.equals(pricingByDate.date) ||
                    (fromDate.before(pricingByDate.date) &&
                        toDate.after(pricingByDate.date))
            );

        if (allBookedDates.length !== totalNights) {
            Sentry.captureMessage(
                `HB-DATES-MISMATCH-${this.productDetails.dealId}`
            );
        }

        return allBookedDates;
    }

    private setCheckinCheckoutUI(
        fromDate: NgbDate,
        toDate: NgbDate,
        totalNights: number
    ): void {
        this.fromDate = fromDate
            ? transformNgbDateToDayJs(fromDate)
            : undefined;

        this.toDate = toDate ? transformNgbDateToDayJs(toDate) : undefined;

        this.totalNights = totalNights;
    }

    private setAccomInventory({
        noOpenCalendar,
    }: { noOpenCalendar?: boolean } = {}): void {
        const { inventory } = this.productDetails;

        this.calendarErrorMessage = !inventory?.length
            ? this.translate.instant('calendarErrors.no-matching-dates')
            : '';

        this.pricingByDateForSelectedServiceOption =
            transformInventoryDto(inventory);

        this.additionalCalendarDayInfo.clear();
        this.pricingByDateForSelectedServiceOption.forEach((pricingByDate) =>
            this.additionalCalendarDayInfo.set(pricingByDate.dateAsString, {
                date: pricingByDate.date,
                tooltipContent: `${this.currencySymbol} ${pricingByDate.sellingPrice}`,
                minLengthOfStay: pricingByDate.minLengthOfStay,
            })
        );
        this.additionalCalendarDayInfo = new Map(
            this.additionalCalendarDayInfo
        );

        this.ref.detectChanges();
        this.emptyBookingData();

        if (!this.calendarErrorMessage && !noOpenCalendar) {
            this.calendarHotel?.openCalendar?.(true);
        } else {
            this.calendarHotel?.refreshCalendarWithoutOpen?.();
        }
    }

    private emptyBookingData(): void {
        this.fromDate = undefined;
        this.toDate = undefined;
        this.totalNights = 0;
        this.bookingSelected = false;
        this.lastSelectedDates = undefined;

        this.emitBookingData(emptyBookingConfiguredData);
    }

    private getBookingTotal({
        fromDate,
        toDate,
        totalNights,
    }: CalendarSelectedDates): {
        total: { totalBooked: number; totalFull: number };
        flightPrice: number;
    } {
        const allDatesBooked = this.getAllBookedDates(
            fromDate,
            toDate,
            totalNights
        );

        const total = allDatesBooked.reduce(
            ({ totalBooked, totalFull }, pricingByDate) => {
                return {
                    totalBooked: totalBooked + pricingByDate.sellingPrice,
                    totalFull: totalFull + pricingByDate.crossedOutPrice,
                };
            },
            { totalBooked: 0, totalFull: 0 }
        );

        const { flightPrice } = this.selectedServiceOptionInternal ?? {};

        if (flightPrice) {
            total.totalBooked += flightPrice;
            total.totalFull += flightPrice;
        }

        return { total, flightPrice };
    }

    private emitBookingData(data: BookingConfiguredData): void {
        this.lastBookingData = data;

        if (this.lastBookingData.productBookingData)
            this.lastBookingData.productBookingData.selectedAccomOptions = [
                this.selectedServiceOptionInternal,
            ];
        else
            this.lastBookingData.productBookingData = {
                id: this.productDetails.dealId,
                selectedAccomOptions: [this.selectedServiceOptionInternal],
            };

        this.bookingConfigured.emit(data);
    }

    private emitBookingOnPricingChange(): void {
        if (!this.lastSelectedDates?.fromDate?.equals) return;

        const { total, flightPrice } = this.getBookingTotal(
            this.lastSelectedDates
        );

        this.emitBookingData({
            ...this.lastBookingData,
            flightPrice,
            totalPrice: total.totalBooked,
            totalFullPrice: total.totalFull,
        });
    }

    private addMaxPaxToAgeGroups(): void {
        if (!this.selectedServiceOptionInternal) return;

        let hasAnyQtyChanged = false;

        this.productDetails.ageGroups.forEach((ageGroup) => {
            ageGroup.maxPax =
                this.selectedServiceOptionInternal[
                    'max' + ageGroup.ageGroupType
                ];

            if (ageGroup.qty > ageGroup.maxPax) {
                ageGroup.qty = ageGroup.maxPax;
                hasAnyQtyChanged = true;
            }
        });

        this.productDetails.ageGroups = [...this.productDetails.ageGroups];

        if (hasAnyQtyChanged)
            this.occupancyComponent.occupancyChanged.next(
                this.productDetails.ageGroups
            );

        this.ref.markForCheck();
    }

    private calcOccupancyTotal() {
        this.maxGuestInternal = this.productDetails.ageGroups.reduce(
            (acc, ageGroup) => acc + (ageGroup.qty ?? 0),
            0
        );

        if (!this.selectedServiceOptionInternal) return;

        const { maxGuest } = this.selectedServiceOptionInternal;

        this.isMaxForSelectedOption = this.maxGuestInternal >= maxGuest;
    }
}
