 <a href="https://marideal.mu/plp/Black%20Friday/34" target="_blank" style="text-decoration:none;" >
  <div *ngIf="showCountdown" class="countdown">
    <img [attr.src]="imagePath"  class="countdown-image" id="countdown-image" />
    <div class="time-container">
      <div 
        id="days" 
        class="time-unit" 
        [ngClass]="{
          'first-target-reached': firstTargetReached,
          'middle-target-reached': middleTargetReached,
          'final-target-reached': finalTargetReached
        }">
        {{ days < 10 ? '0' + days : days }}
      </div>
      <div class="points">:</div>
      <div 
        id="hours" 
        class="time-unit" 
        [ngClass]="{
          'first-target-reached': firstTargetReached,
          'middle-target-reached': middleTargetReached,
          'final-target-reached': finalTargetReached
        }">
        {{ hours < 10 ? '0' + hours : hours }}
      </div>
      <div class="points">:</div>
      <div 
        id="minutes" 
        class="time-unit" 
        [ngClass]="{
          'first-target-reached': firstTargetReached,
          'middle-target-reached': middleTargetReached,
          'final-target-reached': finalTargetReached
        }">
        {{ minutes < 10 ? '0' + minutes : minutes }}
      </div>
      <div class="points">:</div>
      <div 
        id="seconds" 
        class="time-unit" 
        [ngClass]="{
          'first-target-reached': firstTargetReached,
          'middle-target-reached': middleTargetReached,
          'final-target-reached': finalTargetReached
        }">
        {{ seconds < 10 ? '0' + seconds : seconds }}
      </div>
    </div>
    <div class="label-container">
      <div id="label-days" class="time-label">Days</div>
      <div id="label-hours" class="time-label">Hours</div>
      <div id="label-minutes" class="time-label">Minutes</div>
      <div id="label-seconds" class="time-label">Seconds</div>
    </div>
  </div>
  <div id="home-banner-container" *ngIf="!showCountdown" class="home-banner">
    <!-- Normal banner content here -->
  </div>
</a>
